import React from "react";
import { withRouter } from "react-router-dom";

import OurProducts from "./component";

const OurProductsContainer = () => {
  return <OurProducts />;
};

export default withRouter(OurProductsContainer);
