import { RESET } from "../actions";

/**
 * Add a reset to store state for given reducer
 * @param reducer
 * @param initialState - value we are resetting to
 * @param resetType - a custom reset type along with default reset type
 * @returns composedReducer - Higher order function
 */
export const composeResetReducer = (reducer, initialState, resetType) =>
  function composedReducer(state, action) {
    if (action.type === resetType || action.type === RESET) {
      return initialState;
    }

    return reducer(state, action);
  };
