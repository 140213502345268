import React, { useState } from "react";
import { Row, Col, Container } from "reactstrap";
import ApplyInCAMAx from "../components/form/index";
import Alert from "../../../shared/utils/alert";
import { compose } from "redux";
import { withFirebase } from "../../../firebase";
import { validateEmail, validatePhone} from "../../../shared/utils/validations";
import Girl from "../../../assests/images/carrier/girl.png";
import * as actions from "../../../redux/actions";
import { connect } from "react-redux";

const formDetail = {
  firstName: "",
  lasttName: "",
  email: "",
  position: "",
  about: "",
  file: "",
  phone:'',
};
function WorkWithCAMAX(props) {
  const [fields, setFields] = useState(formDetail);
  const [selectedJob , setSelectedJob] = useState();
  const { firebase,availablePost ,setLoading} = props;
  
  const handleChange = (event) => {
    const key = event.target?.id || "position";
    const value = (event.target) ? event.target?.value : event;
    if(key ==="position"){
      setSelectedJob(event.id)
    }
    setFields((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const uploadCV = (file) => {
    setFields((prevState) => ({
      ...prevState,
      ["file"]: file,
    }));
  };
  const handleSubmit = async (e) => {
    // e.preventDefault();
   
    const { firstName, email, lasttName, position, about,phone } = fields;
    if (!firstName || !email || !lasttName || !position || !about||!phone) {
      Alert(400, "All fields are required!!");
      return;
    }
    if (!validateEmail(email)) {
      Alert(400, "Please enter valid email address!!");
      return;
    }
    if(!validatePhone(phone)){
      Alert(400, "Please enter valid phone number!!");
      return;
    }
    setLoading(true)
    const url = await firebase.uploadFile({
      file: fields.file,
      folderName: "CV",
      fileName: "appicantCV",
    });
    let data = fields;
    data = {
      ...data,
      position : data.position.value,
      file: url,
      jobId :selectedJob
    };
   
    
    firebase.applyForJob(data, (res) => {
      if (res) {
        Alert(200, "Applied Successfully!!");
      setLoading(false)

      }
    });

    setFields(formDetail);
  };
  return (
    <section className="workWithCAMAXWrapper">
      <Container>
        <Row>
          <Col lg={5} className="d-lg-block d-none">
            <img src={Girl} className="img-fluid" />
          </Col>
          <Col lg={7}>
            <h3>
              {" "}
              Do you want to work with <span>CA Max</span>?{" "}
            </h3>
            <ApplyInCAMAx
              values={fields}
              onChange={handleChange}
              onSubmit={handleSubmit}
              uploadCV={uploadCV}
              availablePost = {availablePost}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loader.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (payload) => dispatch(actions.setLoading(payload)),
  };
};
export default compose(withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(WorkWithCAMAX);

// export default compose(withFirebase)(WorkWithCAMAX);
