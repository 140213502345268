export const About = {
  CAMAX: [
    "Multi Company System (No Limit of Companies)",
    "Separate Location for Personal Accounts",
    "E-Way and E-Invoicing Automatic thru API and JSON (Both Methods)",
    "Latest and Time-to-Time Amended GST System, Very Easy and User Friendly Return Making Modules",
    "Invoicing & Inventory Systems for All Manufacturers, Traders, Institutes, Professionals. Customized Billing",
    "Pharma Trade Billing & Inventory",
    "Barcode Based Retail Counter POS Billing.",
    "Customer Based Invoice Format Designing.",
    "Option of Multiple (Any Number of) Invoice Designs and page layouts.",
    "Design your Own Formats.",
    "Company/Brand Logos on Invoice Formats.",
  ],
  PAYMAX: [
    " Payroll System for small to large companies",
    "User-defined Salary Structures",
    "All Statutory Reports",
    "ESI/EPF E-Filing",
    "Salary Slips, Wages Slips",
    "Salary Registers, Wages Register",
    "Location Wise,",
    "Designation Wise,",
    "Department Wise",
    "Contractor Based",
  ],
  CDADMAX : [
    "Advertising Agency Management System",
                  "Includes: Release Orders, Tariff Cards, Single/Combined Editions,",
                "Client Billing, Publication Billing, Cr.Notes, DailyScheduling,",
                "Financial Accounting, Insertion-Wise Profits", 
                "Publication/Client Payment Outstanding System.",
  ]
};
