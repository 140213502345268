import React from "react";
import { Button, Container, Row, Col } from "reactstrap";
import bannerImage from "../../../../assests/images/home/banner-img.png";
import { withRouter } from "react-router-dom";
// import {getAllUsers} from '../../../../../functions/src/index'
const DescriptionContainer = (props) => {

  // const handleClick = ()=>{
  //   getAllUsers()
  // }

  return (
    <div className="banner-wrapper">
      <Container>
        {/* <Button onClick = {handleClick}> Test cron </Button> */}
        <Row>
          <Col lg={6}>
            <div className="banner-content">
              <h1>A Complete Accounting System for all Businesses</h1>
              <p style={{textAlign:'justify'}}>
              We would like to introduce our company Microsys & Equipments that has been in IT Industry since 1989 (for the past 30+ years). Microsys & Equipments have been an established and popular company with an excellent track record for the best customer satisfaction. Our Company has never compromised on quality and services provided to the customer. We believe in keeping the customers happy and providing them with quality software solutions. Our Company has an excellent team of developers and customer support executives which enable us to deliver quality software, quality implementation, quality training and quality customer support.

              </p>
              <div className="banner-buttons">
                <Button onClick = {()=>{props.history.push('/our-products')}} color="bordered">Our Products</Button>
                <Button onClick = {()=>{props.history.push('/downloads')}} color="theme">Downloads</Button>
              </div>
            </div>
          </Col>
          <Col lg={6} className="d-lg-block d-none">
            <div>
              <img src={bannerImage} className="img-fluid" alt="banner" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(DescriptionContainer);
