import { ROUTES } from "../../../../shared/constants";

const tabList = [
  {
    id: "home",
    label: "Home",
    link: ROUTES.home,
  },
  {
    id: "ourProducts",
    label: "Products",
    link: ROUTES.ourProducts,
  },
  {
    id: "downloads",
    label: "Downloads",
    link: ROUTES.downloads,
  },
  {
    id: "employers",
    label: "Recruiters/Employers",
    link: ROUTES.employers,
  },
  {
    id: "jobSeekers",
    label: "Job Seekers",
    link: ROUTES.jobSeekers,
  },
  {
    id: "carrier",
    label: "Carrier with us",
    link: ROUTES.carrier,
  },
  {
    id: "becomeSalesPartner",
    label: "Become Sales Partner",
    link: ROUTES.becomeSalesPartner,
  },
  
  {
    id: "contactUs",
    label: "Contact Us",
    link: ROUTES.contactUs,
  },
  // {
  //   id: "manageLicences",
  //   label: "Manage Licences",
  //   link: ROUTES.manageLicences,
  // },
];

export default tabList;
