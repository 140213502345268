import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ManageLicence from "./component";

const ManageLicenceContainer = (props ) => {
  const { user: { isConfirmed } } =props
  const [isRegisterMode, setRegisterMode] = useState(false);

  const handleToggleAuthMode = () => {
    setRegisterMode((prevState) => !prevState);
  };
  return (
    <ManageLicence
      isAuthenticated={isConfirmed}
      isRegister={isRegisterMode}
      onToggleAuthMode={handleToggleAuthMode}
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(ManageLicenceContainer);
