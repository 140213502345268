import React, { useState } from "react";
import NavBar from "../navBar";
import MobileMenu from "../../../../assests/images/mobile.png";
import Cancel from "../../../../assests/images/cancel.svg";

const SubHeader = ({ selectedTab, onSelectTab }) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div>
      <button
        onClick={() => {
          setIsVisible(!isVisible);
        }}
        className="d-lg-none mobile-menu-btn"
      >
        <img src={MobileMenu} />
      </button>
      <div className={`mobile-menu-wrapper ${isVisible ? "isVisible" : ""}`}>
        <div
          className="backdrop"
          onClick={() => {
            setIsVisible(!isVisible);
          }}
        ></div>
        <div className="mobile-menu-content">
          <button
            className="close"
            onClick={() => {
              setIsVisible(!isVisible);
            }}
          >
            <img src={Cancel} />
          </button>
          <NavBar value={selectedTab} onClick={onSelectTab} setIsVisible = {setIsVisible}/>
        </div>
      </div>
    </div>
  );
};

export default SubHeader;
