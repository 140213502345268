import React from "react";

const PaymentSystem = () => {
  return (
    <div
      className={"text-center d-flex align-items-center justify-content-center"}
      style={{
        minHeight: "90vh",
      }}
    >
      <div>
        <p>Welcome to CAMAX PaymentSystem</p>
      </div>
    </div>
  );
};

export default PaymentSystem;
