import React from "react";
import { Col, Row } from "reactstrap";
import Location from "../../../../assests/images/contact/location.png";
import Phone from "../../../../assests/images/contact/phone.png";
import Email from "../../../../assests/images/contact/mail.png";
import Helpdesk from "../../../../assests/images/contact/helpdesk.png";
import ContactUsImage from "../../../../assests/images/contact/contactusImage.png";

const ContactDetails = () => {
  return (
    <div className="contact-details-wrapper">
      {/* <h3>Lorem Ipsum is simply Lorem</h3> */}
      <h2>
      Share your details to become our <span>Sales Partner</span> .
      </h2>

      <Row>
        <Col sm={12} className="text-center">
          <img src={ContactUsImage} alt="" className="contact-mobile-image" />
        </Col>
      </Row>

      <Row className="my-lg-5 px-3 mb-5">
        <Col md={6} xl={3} className="p-2">
          <div className="contact-info">
            <img src={Location} alt="location" />
            <p className="contact-info-tile">Location </p>
            <p>DSS - 147 P, Sector - 11, Panchkula- Haryana -134109, India</p>
          </div>
        </Col>
        {/* <Col md={6} xl={3} className="p-2">
          <div className="contact-info">
            <img src={Helpdesk} alt="phone" />
            <p className="contact-info-tile">Helpdesk </p>
            <p>+91-9814-200-063</p>
            <p>+91-9814-220-063</p>
          </div>
        </Col> */}
        <Col md={6} xl={3} className="p-2">
          <div className="contact-info">
            <img src={Email} alt="message" />
            <p className="contact-info-tile">Email </p>
            <p>microsysindia63@gmail.com</p>
            <p>microsysindia63@hotmail.com</p>
          </div>
        </Col>
        {/* <Col md={6} xl={3} className="p-2">
          <div className="contact-info">
            <img src={Phone} alt="message" />
            <p>+91-9814-000-063</p>
          </div>
        </Col> */}
      </Row>
    </div>
  );
};

export default ContactDetails;
