import React from "react";
import { Col, Container, Row, Button, CardBody, Card } from "reactstrap";
import CarrierBannerImg from "../../../assests/images/commonImages/carrierbannerimg.png";

function OpenPositions({positions}) {
  let desText = "We are looking to expand our team. Are you passionate to be a part of our team? Drop us your resume, we have the right work environment where you can explore your career. "
  if (positions == 0){
    desText = "We aren't looking to expand our team at the moment. You can still post your CV to our email so we can contact you when we have any openings."
  }
  return (
    <div className="employee-wrapper">
      <Container>
        <Row className="align-items-center">
          <Col lg={7}>
            <div className="employee-form-wrapper open-position-wrapper">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={5}>
                      <div className="count">
                        <h1> {positions}</h1>
                      </div>
                    </Col>
                    <Col md={7}>
                      <div className="open-position-content">
                        <h3>Open positions at CA MAX</h3>
                       
                        <p>
                       {desText}
                        </p>
                        {/* <Button color="bordered" className="explore-btn">
                          Explore
                        </Button> */}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col lg={5} className="d-lg-block d-none">
            <img
              src={CarrierBannerImg}
              alt="carrierPng"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default OpenPositions;
