import React from "react";
import { withRouter } from "react-router-dom";

import JobSeekers from "./component";

const JobSeekersContainer = () => {
  return <JobSeekers />;
};

export default withRouter(JobSeekersContainer);
