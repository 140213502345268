import React, { useState } from "react";
import ContactForm from "./component";
import { sendMail } from "../../../../shared/utils/email";
import Alert from "../../../../shared/utils/alert";
import AlertModal from '../../../../shared/utils/alertModal'
import {contactUs} from '../../../../shared/utils/emailTemplates/contactUsTemplate'
import {
  validateEmail,
  validatePhone,
} from "../../../../shared/utils/validations";

const formDetail = {
  name: "",
  email: "",
  phone: "",
  message: "",
  // serviceType: "",
};

const ContactFormContainer = () => {
  const [fields, setFields] = useState(formDetail);
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = ()=>{
    setIsOpen(!isOpen)
  }
  
  const handleChange = (event) => {
    const key = event.target.id;
    const value = event.target.value;
    setFields((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSelect = (key, value) => {
    setFields((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSend = () => {
    const { name, email, phone, message } = fields;
    if (!name || !email || !phone || !message ) {
      Alert(400, "All fields are required!!");
      return;
    }

    if (!validateEmail(email)) {
      Alert(400, "Please enter valid email address!!");
      return;
    }

    if (!validatePhone(phone)) {
      Alert(400, "Please enter valid Phone number!!");
      return;
    }
    setFields(formDetail)
    setIsOpen(true)
    // const content = `<div><p>Name: <b>${name}</b></p><p>Email: <b>${email}</b></p><p>Phone: <b>${phone}</b></p><p>Services: <b>${serviceType}</b></p><p>Message:</p><p>${message}</p></div>`;
   let constactInfo = {
     name : name,
     email : email,
     phone : phone,
    //  service : serviceType,
     message : message
   }
    const content = contactUs(constactInfo)
    const data = {
      from :"mmahajaninnow8@gmail.com",
      to: "microsysindia63@gmail.com",
      subject: "Inquiry from CaMax",
      content,
    };

    sendMail(data)
      .then(() => {
        Alert(200, "Email sent successfully.");
      })
      .catch(() => {
        Alert(400, "Unable to send email at this time.");
      });
  };

  return (
    <>
    <ContactForm
      values={fields}
      onChange={handleChange}
      onSelect={handleSelect}
      onSend={handleSend}
    />
    <AlertModal isOpen = {isOpen} onToggle = {toggleModal} text = "Thanks for contacting CAMAX" />
    </>
  );
};

export default ContactFormContainer;
