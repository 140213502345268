import React from 'react';
import PrivacyPoliciesContainer from './component'
function PrivacyPolicies(props) {
  return (
    <div>
      <PrivacyPoliciesContainer/>
    </div>
  );
}

export default PrivacyPolicies;