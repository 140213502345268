import React,{ useState } from 'react';
import ForgotPassword from './component'
import { validateForgetPasswordForm,} from "../signIn/utils";
import Alert from "../../shared/utils/alert";
import { compose } from "redux";
import { withFirebase } from "../../firebase";
import AlertModal from "../../shared/utils/alertModal";

function ForgetPasswordContainer(props) {
  const {firebase} = props
  const [newpassword, setNewPassword] = useState("");
  const [email, setEmail] = useState("");
 const [isOpen,setIsOpen] = useState(false)

  const [confirmPassword, setConfirmPassword] = useState("");
  const handleChange = (event) => {
    const key = event.target.id;
    const value = event.target.value;
    if (key === "email") {
      setEmail(value);
    }
    // if (key === "newpassword") {
    //   setNewPassword(value);
    // }
    // if (key === "confirmPassword") {
    //   setConfirmPassword(value);
    // }
  };
  const toggle =()=>{
    setIsOpen(!isOpen)
  }
  const handleForgotoPassword = async ()=>{
    const validationResponse = validateForgetPasswordForm({email, newpassword,confirmPassword})
    if (!!validationResponse) {
      Alert(400, validationResponse);
      return;
    }
    setIsOpen(true)
    setEmail('');
    // setNewPassword('');
    // setConfirmPassword('')
    firebase.forgotPassword(email)
    }

  return (
    <div>
      <ForgotPassword  
     newpassword = {newpassword}
     confirmPassword = {confirmPassword}
     email = {email}
     onChange={handleChange}
     handleForgotoPassword = {handleForgotoPassword}
     />
    <AlertModal isOpen= {isOpen} onToggle={toggle} text = "Please check your email to reset your password"/>
    </div>
  );
}
export default compose(
  withFirebase,
)(ForgetPasswordContainer);
// export default ForgetPasswordContainer;