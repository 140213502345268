import React from "react";
import { Container, Row, Col } from "reactstrap";
import snapshot from "../../../../../../assests/images/home/phone-features.png";
import Camax1 from "../../../../../../assests/images/products/camax1.png";
import Camax2 from "../../../../../../assests/images/products/camax2.png";
import Camax3 from "../../../../../../assests/images/products/camax3.png";

const images = {
  "01": [Camax1],
  "02": [Camax2],
  "03": [Camax3],
};

const ProductContainer = ({ id, count, name, features, isShowImageFirst }) => {
  return (
    <Row className="mt-lg-5 pt-5">
      {!isShowImageFirst && (
        <Col md={6} className="d-md-none">
          <div className="text-md-right text-center">
            {/* <h1>{count}</h1> */}
            {images[count].map((path) => (
              <img src={path} className="img-fluid" alt="banner" />
            ))}
          </div>
        </Col>
      )}
      {isShowImageFirst && (
        <Col md={6}>
          <div className="text-md-left text-center">
            {/* <h1>{count}</h1> */}
            {images[count].map((path) => (
              <img src={path} className="img-fluid" alt="banner" />
            ))}
          </div>
        </Col>
      )}
      <Col md={6}>
        <div>
          <h4 className="custom-title mt-5">
            <span className="text-contain">
              <span className="text">{name}</span>
              <span className="backText">{name}</span>
            </span>
          </h4>
          <ul>
            {features.map((feature, index) => (
              <li key={id + index}>{feature}</li>
            ))}
          </ul>
        </div>
      </Col>
      {!isShowImageFirst && (
        <Col md={6} className="d-md-block d-none">
          <div className="text-md-right text-center">
            {/* <h1>{count}</h1> */}
            {images[count].map((path) => (
              <img src={path} className="img-fluid" alt="banner" />
            ))}
          </div>
        </Col>
      )}
    </Row>
  );
};

export default ProductContainer;
