export const SET_USER = "SET_USER";
export const UPDATE_USER = "UPDATE_USER";

/**
 * @method setUser : To save user details
 *
 * @param {object} payload
 * Contains following:-
 * {
 * @param {string} uid
 * @param {string} role
 * @param {string} username
 * @param {string} companyName
 * @param {string} address
 * @param {string} city
 * @param {string} state
 * @param {string} country
 * @param {string} gstInNumber
 * @param {string} email
 * @param {string} photoUrl
 * }
 */
export const setUser = (payload) => ({
  type: SET_USER,
  payload,
});

/**
 * @method setUser : To save user details
 *
 * @param {object} payload
 * Contains following any of following:-
 * {
 * @param {string} uid
 * @param {string} role
 * @param {string} username
 * @param {string} companyName
 * @param {string} address
 * @param {string} city
 * @param {string} state
 * @param {string} country
 * @param {string} gstInNumber
 * @param {string} email
 * @param {string} photoUrl
 *
 * }
 */
export const updateUser = (payload) => ({
  type: UPDATE_USER,
  payload,
});
