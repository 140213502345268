import TabList from "../navBar/data";

/**
 * @method getSelectedTab : To get current selected tab
 *
 * @returns selectedTab
 */
export const getSelectedTab = () => {
  const path = window.location.pathname.toLowerCase();
  const tab = TabList && TabList.find((value) => value.link.toLowerCase() === path);
  if (tab) {
    return tab.id;
  }

  return "home";
};
