import React, { useState, useEffect } from "react";

import Header from "./component";

const SCROLL_POSITION = 70;

const HeaderContainer = ({ transparentHeader }) => {
  const [sticky, setSticky] = useState(false);

  const handleScroll = () => {
    let scrollPosition = window.scrollY;
    if (scrollPosition >= SCROLL_POSITION) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return <Header isSticky={sticky} isTransparent={transparentHeader} />;
};

export default HeaderContainer;
