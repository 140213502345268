import React,{useEffect,useState} from "react";
import OpenPositions from "./components/openPositions"
import AboutCompany from "./components/aboutCompany";
import CarrierOptions from './components/carrierOptions'
import WorkWithCAMAX from "./components/workWithCAMAX"
import { withFirebase } from "../../firebase";
import { compose } from "redux";
import * as actions from "../../redux/actions";
import { connect } from "react-redux";

const Carrier = (props) => {
  const {firebase,setLoading} = props
  const [post, setPost] = useState()
  const [positionToApply, setPositionToApply] = useState()
  useEffect(()=>{
    getAvailableJobsInCAMAX()
  },[])
  const getAvailableJobsInCAMAX=async()=>{
    setLoading(true)
   const result = await firebase.getAllPostByCAMAX()
   setPost(result)
   const positions = result.map(el=>{
     return {
      label : el.jobTitle,
       value : el.jobTitle,
       id : el.id,
     }
   })
   setPositionToApply(positions)
   setLoading(false)
  }
  return (
    <div>
     <OpenPositions positions = {post ? post.length : 0}/>
     <AboutCompany/>
     <CarrierOptions carrierOptions = {post}/>
     <WorkWithCAMAX availablePost = {positionToApply ? positionToApply : null}/>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.loader.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (payload) => dispatch(actions.setLoading(payload)),
  };
};
export default compose(withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(Carrier);

// export default compose(withFirebase)(Carrier);

// export default Carrier;
