import { SET_IS_LOADING } from "../actions";

const initialState = {
  isLoading: false,
};

const LoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    }

    default:
      return state;
  }
};

export default LoaderReducer;
