import React from "react";
import ContactMapImg from "../../assests/images/contact/ContactMapImg.png";
import MapContainer from "./components/googlemap/index";

export default () => (
  <section className="google-map-wrapper">
    <div className="contact-image">
      <img src={ContactMapImg} alt="" />
    </div>

    <div className="google-map-content">
      <MapContainer />
    </div>
  </section>
);
