import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import CAMAX from "../../../assests/images/commonImages/Ca_max.png";
import Mail from "../../../assests/images/commonImages/mail.png";
import Phone from "../../../assests/images/commonImages/phone.png";
import Box from "../../../assests/images/commonImages/box.png";
import Wallet from "../../../assests/images/commonImages/wallet.png";
import { withFirebase } from "../../../firebase";
import { compose } from "redux";
import ReadMore from "../../common/readMore"
import * as actions from "../../../redux/actions";
import { connect } from "react-redux";
import Pagination from "react-js-pagination"
let limit = 10
function RecentPosts(props) {
  const { firebase,setLoading } = props;
  const [postByEmployees, setPostByemployees] = useState([]);
  const [page,setPage] = useState(1)
  const [totalItem, setItem] =useState(0)

  useEffect(() => {
    getEmployeePosts(page);
  }, []);

  const pageChange = (pageNumber)=>{
    setPage(pageNumber)
    getEmployeePosts(pageNumber)
  }

  const getEmployeePosts = async (pageNumber) => {
    const offset = (pageNumber - 1) *  limit 
    setLoading(true)
    const result = await firebase.getAllPostByEmployee(offset,limit);
    setPostByemployees(result.data);
    setItem(result.totalData)
    setLoading(false)
  };
  const dummy = "Microsys & Equipments is an ISO 9001 - 2008 Certified CompanyLocated with Office at Panchkula. Microsys is one of the oldest and leading Software Development company of this Region. Incorporated in 1989 the company has been growing steadily through its unflinching commitment to its clients, its staff, associates and to the society around it. Our Softwares are being used by thousands of satisfied Clients which include prominent Industries, Trading houses, Professionals, Pharmaceutical Industries and Distributors, Advertisement Agencies, Etc.. Based on innovative policies , guided by an enlightened management and supported by an uptodate infrastructure Microsys thrives on humane professionalism"
  return (
    <div className="recent-jobs-post-wrapper">
      <h2>
        <span>Recent profile posts by Job Seekers </span>
      </h2>
      <ul>
        {
          postByEmployees && postByEmployees.map(({jobTitle,email,phone,experience,ctc,about} )=> {
            return (
              <li>
                <h3> {jobTitle} </h3>
                <div className="accountant-content-wrapper">
                  <div className="accountant-content">
                    <img src={CAMAX} />
                    <p>CA max</p>
                  </div>
                  <div className="accountant-content">
                    <img src={Mail} />
                    <p>{email}</p>
                  </div>
                  <div className="accountant-content">
                    <img src={Phone} />
                    <p>{phone}</p>
                  </div>
                  <div className="accountant-content">
                    <img src={Box} />
                    <p>{experience} years</p>
                  </div>
                  <div className="accountant-content">
                    <img src={Wallet} />
                    <p>{ctc}lac</p>
                  </div>
                </div>
                <ReadMore text = {about || ''}/>
                {/* <p>
                  {dummy}
                  {about}
          </p> */}
                {/* <Button color="theme" className="apply-btn">
                  Apply
          </Button> */}
              </li>
            )
          })
        }
      </ul>
      {
       (totalItem  >  limit) &&  <Pagination
       className="mt-3 mx-auto w-fit-content"
       itemClass="page-item"
       linkClass="page-link"
       activeClass="active"
       activePage={page}
       itemsCountPerPage={limit}
       totalItemsCount={totalItem}
       // pageRangeDisplayed={5}
       onChange={pageChange}
       />
      }
     
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    isLoading: state.loader.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (payload) => dispatch(actions.setLoading(payload)),
  };
};
export default compose(withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(RecentPosts);
// export default compose(withFirebase)(RecentPosts);
