import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import { withFirebase } from "../../../../firebase";
import { compose } from "redux";
import * as actions from "../../../../redux/actions";

import Profile from "./component";
import {validateFormData} from './utils'
import Alert from "../../../../shared/utils/alert";
const userDetail = {
  username: "",
  companyName: "",
  address: "",
  city: "",
  state: "",
  country: "",
  gstInNumber: "",
  email: "",
  // password: "",
  // confirmPassword: "",
  phone : "",
};

const ProfileContainer = ({user,firebase,setUser}) => {
  const [fields, setFields] = useState(userDetail);
  useEffect(()=>{
  setFields(user)
  },[])
  const handleChange = (values) => {
    setFields(values);
  };

  const handleSave = async () => {
    const validationResponse = validateFormData(fields);
    if (!!validationResponse) {
      Alert(400, validationResponse);
      return;
    }
    // const password = fields.password
    let data = Object.assign(fields)
    delete data["password"]
    delete data["confirmPassword"]
    await firebase.updateUser(user.uid,data)
    setFields(userDetail)
    setUser(data)
  };

  const handleChangeProfilePicture = () => {};
  return (
    <Profile
      values={fields}
      onChange={handleChange}
      onChangeProfilePicture={handleChangeProfilePicture}
      onSave={handleSave}
    />
  );
};


const mapStateToProps = (state) => {
  return {
    isLoading: state.loader.isLoading,
    user: state.user
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (payload) => dispatch(actions.setLoading(payload)),
    setUser: (payload) => dispatch(actions.setUser(payload)),
  };
};
export default compose(withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(ProfileContainer);
// export default ProfileContainer;
