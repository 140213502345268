import { combineReducers } from "redux";

import UserReducer from "./user";
import LoaderReducer from "./loader";

const rootReducer = combineReducers({
  user: UserReducer,
  loader: LoaderReducer,
});

export default rootReducer;
