import React from "react";
import { withRouter } from "react-router-dom";
import {ROUTES} from '../../../../shared/constants'

const Products = (props) => {
  const {history} = props
  const redirectTo = (navigateTo)=>{
    history.push(navigateTo);
    
  }
 return  (
  <div className="footer-detail">
    <h3>Products</h3>
    {/* <p > dsjfgdsfgdk</p> */}
    <p onClick = {()=>{redirectTo(ROUTES.ourProducts)}}>Our Products</p>
    <p onClick = {()=>{redirectTo(ROUTES.downloads)}}>Downloads</p>
    
  </div>
)};
export default withRouter(Products)

// export default Products;
