import React from "react";
import formFields from "./formFields";
import {
  Container,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  InputGroup,
} from "reactstrap";

import Arrowleft from "../../../../assests/images/contact/arowleft.png";

function EmployeeForm(props) {
  const { onPost, onChange, values } = props;
  return (
    <>
      <div className="employee-details-wrapper">
        {/* <h3>Lorem Ipsum is simply Lorem</h3> */}
        <h2>
          {/* Lets get in <span>Touch </span> */}
          Get a step closer to your dream job. Complete your profile and stand ahead in the hiring pool.
        </h2>
      </div>
      <div className="employee-form-wrapper">
        <Card>
          <CardBody>
            {Object.keys(formFields).map((section) => {
              const heading =
                section === "section1" ? (
                  <h2>Employee Form </h2>
                ) : section === "section2" ? (
                  <h2 className="mt-4">Registration</h2>
                ) : (
                  ""
                );
              const colvalue = section === "section3" ? 12 : 4;
              return (
                <div>
                  <Row>
                    <Col md={12}>{heading}</Col>
                    {Object.keys(formFields[section]).map((key) => {
                      const field = formFields[section][key];
                      return (
                        <Col md={colvalue}>
                          <FormGroup>
                            <label>{field.label}</label>
                            {field.required && (
                              <span className="required">*</span>
                            )}
                            <InputGroup
                              className={`${field.icon ? "icon-group" : ""}`}
                            >
                              <Input
                                value={values[key]}
                                type={field.type}
                                onChange={onChange}
                                id={field.id}
                              />
                              <img src={field.icon} />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              );
            })}
            <div className="text-center">
              <Button
                onClick={onPost}
                color="theme"
                className="employee-submit-button"
              >
                Post <img src={Arrowleft} className="ml-1" />
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default EmployeeForm;
