import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../firebase";

import SignIn from "./component";
import { ROUTES } from "../../shared/constants";
import Alert from "../../shared/utils/alert";
import { validateFormData,} from "./utils";
import * as actions from "../../redux/actions";
import AlertModal from "../../shared/utils/alertModal";
import ForgotPasswordLinkModal from './ForgotPasswordLinkModal'
const SignInContainer = ({
  history,
  firebase,
  setUser,
  setLoading,
  onToggleAuthMode,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
 const [isOpen,setIsOpen] = useState(false)
 const [isForgotPassword, setIsForgotPassword] = useState(false)
  const handleChange = (event) => {
    const key = event.target.id;
    const value = event.target.value;
    if (key === "email") {
      setEmail(value);
    }
    if (key === "password") {
      setPassword(value);
    }
  };
const toggle =()=>{
  setIsOpen(!isOpen)
}
 const togglePasswordModal =()=>{
  // setIsForgotPassword(!isForgotPassword)
  history.push('/forgotPassword')
 }

  const handleSignIn = () => {
    const validationResponse = validateFormData({ email, password });
    if (!!validationResponse) {
      Alert(400, validationResponse);
      return;
    }

    setLoading(true);

    firebase
      .signInWithEmailPassword(email, password)
      .then((res) => {
        if (res.status === 200) {
          // console.log("resresres=",res.data[0])
          const isConform = res.data[0].isConfirmed
          setIsOpen(!isConform)
          isConform && Alert(200, "SignIn sucessfully!!");
          setUser(res.data[0]);
          setLoading(false);
          history.push(ROUTES.manageLicences);
        } else {
          setLoading(false);
          Alert(400, (res.data && res.data.message) || res.message);
        }
      })
      .catch(() => {
        setLoading(false);
        Alert(
          400,
          "Somthing went wrong. Unable to signIn at this time. Please try again!!"
        );
      });
  };

  return (
    <>
   <SignIn
      email={email}
      password={password}
      onChange={handleChange}
      onSignIn={handleSignIn}
      onToggleAuthMode={onToggleAuthMode}
      togglePasswordModal = {togglePasswordModal}
    /> :
    {/* <ForgotPasswordLinkModal isOpen = {isForgotPassword} onToggle = {togglePasswordModal}/> */}
    <AlertModal isOpen= {isOpen} onToggle={toggle} text = "Please confirm your email to login"/>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (payload) => dispatch(actions.setUser(payload)),
    setLoading: (payload) => dispatch(actions.setLoading(payload)),
  };
};

export default compose(
  withFirebase,
  withRouter,
  connect(null, mapDispatchToProps)
)(SignInContainer);
