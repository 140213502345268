import React from "react";
import { withRouter } from "react-router-dom";

import Downloads from "./component";

const DownloadsContainer = () => {
  return <Downloads />;
};

export default withRouter(DownloadsContainer);
