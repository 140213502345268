import React from "react";
import {
  Row,
  Input,
  Label,
  Modal,
  ModalBody,
  FormGroup,
  Col,
  Button,
} from "reactstrap";
import closeIcon from "../../assests/images/close.svg";

function AlertModal(props) {
  const { isOpen, onToggle, text } = props;
  return (
    <Modal
      isOpen={isOpen}
      toggle={onToggle}
      centered
      className="confirmation-modal"
    >
      <ModalBody>
        {/* <button className="modal-close" >
        <img src={closeIcon} alt="close" />
      </button> */}
        <div className="confirmation-wrapper text-center">
          <h3>{text}</h3>
          <Button color="theme" onClick={onToggle}>
            {" "}
            OK
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default AlertModal;
