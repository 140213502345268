import React from 'react';
import TermsConditionsContainer from './component'
function TermsConditions(props) {
  return (
    <div>
      <TermsConditionsContainer/>
    </div>
  );
}

export default TermsConditions;