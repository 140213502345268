import { Button } from "reactstrap";
import React, { useEffect, useState } from "react";
import CAMAX from "../../../assests/images/commonImages/Ca_max.png";
import Mail from "../../../assests/images/commonImages/mail.png";
import Phone from "../../../assests/images/commonImages/phone.png";
import Box from "../../../assests/images/commonImages/box.png";
import Wallet from "../../../assests/images/commonImages/wallet.png";
import ReadMore from "../../common/readMore"
import { withFirebase } from "../../../firebase";
import { compose } from "redux";
import * as actions from "../../../redux/actions";
import { connect } from "react-redux";
import Pagination from "react-js-pagination"
let limit = 10
function RecentJobs(props) {
  const { firebase,setLoading } = props;
  const [postByEmployer, setPostByEmployer] =useState([])
  const [page,setPage] = useState(1)
  const [totalItem, setItem] =useState(0)
  useEffect(() => {
    getEmployerPost(page);
  }, []);
  const getEmployerPost = async (pageNumber) => {
    const offset = (pageNumber - 1) *  limit 
    setLoading(true)
   const result = await firebase.getEmployerPost(offset,limit);
   setPostByEmployer(result.data)
   setItem(result.totalData)
   setLoading(false)

  };

  const pageChange = (pageNumber)=>{
    setPage(pageNumber)
    getEmployerPost(pageNumber)
  }

  const handleClick = async ()=>{
   const result =  await firebase.croneJobTest()
   console.log("resultresultresult=",result)
  }
 
  return (
    <div className="recent-jobs-post-wrapper">
      <h2>
        <span>Recent Job openings by Recruiters </span>
      </h2>
      {/* <Button onClick = {handleClick}> Test Crone</Button> */}
      <ul>
        {
          postByEmployer && postByEmployer.map(({jobTitle,email,phone,experience,ctc,description})=>{
            return (
              <li>
                <h3> {jobTitle} </h3>
                <div className="accountant-content-wrapper">
                  <div className="accountant-content">
                    <img src={CAMAX} />
                    <p>CA max</p>
                  </div>
                  <div className="accountant-content">
                    <img src={Mail} />
                    <p>{email}</p>
                  </div>
                  <div className="accountant-content">
                    <img src={Phone} />
                    <p>{phone}</p>
                  </div>
                  <div className="accountant-content">
                    <img src={Box} />
                    <p>{experience} years</p>
                  </div>
                  <div className="accountant-content">
                    <img src={Wallet} />
                    <p>{ctc}lac</p>
                  </div>
                </div>
                {/* <p>
                  {dummy}
                  {description}
          </p> */}
          <ReadMore text = {description || ''}/>
                {/* <Button color="theme" className="apply-btn">
                  Apply
          </Button> */}
              </li>
            )
          })
        }
       
      </ul>
      {
       (totalItem  >  limit) &&  <Pagination
       className="mt-3 mx-auto w-fit-content"
       itemClass="page-item"
       linkClass="page-link"
       activeClass="active"
       activePage={page}
       itemsCountPerPage={limit}
       totalItemsCount={totalItem}
       // pageRangeDisplayed={5}
       onChange={pageChange}
       />
      }
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    isLoading: state.loader.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (payload) => dispatch(actions.setLoading(payload)),
  };
};
export default compose(withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(RecentJobs);
// export default compose(withFirebase)(RecentJobs);
