import React from "react";
import { Container, Row, Col } from "reactstrap";


const AboutUsContainer = () => {
  return (
    <section className="about-wrapper text-center">
      <Container>
        <Row>
          <Col md={12}>
            <h4 className="custom-title">
              <span className="text-contain">
                <span className="text">About Us</span>
                <span className="backText">About Us</span>
              </span>
            </h4>
            <p>
           <b> Microsys & Equipments </b> have been a part of transforming the digital world since 1989. We are an ISO 9001-2008 certified company. As one of the leading Software development company, CA MAX has been a part of thousands of companies from prominent industries, Trading houses, Accounts Professionals, Govt. Offices, Institutes and all sorts of Business Houses.
            </p>
          </Col>
        </Row>
{/* 
        <Row className="mt-5 pt-5">
          <Col md={4}>
            <img src={about1} alt="img1" />
            <h3>Lorem Ipsum simply dummy</h3>
            <p>
              Microsys & Equipments is an ISO 9001 - 2008 Certified Company
              Located with Offices at Chandigarh and Panchkula. Microsys is one
              of the oldest and leading Software Development company of this
              Region.
            </p>
          </Col>
          <Col md={4}>
            <img src={about2} alt="img2" />
            <h3>Lorem Ipsum simply dummy</h3>
            <p>
              Microsys & Equipments is an ISO 9001 - 2008 Certified Company
              Located with Offices at Chandigarh and Panchkula. Microsys is one
              of the oldest and leading Software Development company of this
              Region.
            </p>
          </Col>
          <Col md={4}>
            <img src={about3} alt="img3" />
            <h3>Lorem Ipsum simply dummy</h3>
            <p>
              Microsys & Equipments is an ISO 9001 - 2008 Certified Company
              Located with Offices at Chandigarh and Panchkula. Microsys is one
              of the oldest and leading Software Development company of this
              Region.
            </p>
          </Col>
        </Row> */}
      </Container>
    </section>
  );
};

export default AboutUsContainer;
