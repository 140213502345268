import React, { useState,useEffect } from "react";
import Dashboard from "./component";
import { withFirebase } from "../../firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
let IntialData ;
const DashboardContainer = (props) => {
  const [tab, setTab] = useState("dashboard");
  const [licenceData, setLicenceData] = useState();
  const {firebase,user,setLoading}  = props;
  const [paymentDone , setPaymentDone] = useState(false)
  const [coupon, setCoupon] = useState()
  const handleSelectTab = (key) => {
    setTab(key);
  };

  useEffect(()=>{
    getLicenses()
 },[])
 useEffect(()=>{
   if(paymentDone){
    getLicenses()
   }
 },[paymentDone])

 const togglePayment = ()=>{
   setPaymentDone(!paymentDone)
   getLicenses()
 }

 

const getLicenses= async()=>{
  setLoading(true)
 const result = await firebase.getUsersLicences(user.uid)
 IntialData = result
 setLicenceData(result)
 setLoading(false)
 
}

const addProductTable = ()=>{
let data = {
  name: "CD AD Max",
  singleUserAmount: 1200,
  multiUserAmount: 1500,
  selectedPlan : 'single',
  productId : "cdadmax",
  productName : "CD AD MAX",
}
  firebase.addProducts(data)
}

  const handleSearch = (value) => {
   
   if(value.length >= 3){
    const newArray = licenceData.filter(el=>{
      const id = el.id.toLowerCase()
      const val = value.toLowerCase()
      return id.includes(val)
    })
    setLicenceData(newArray)
   }else{
    setLicenceData(IntialData)
   }
  };
  return (
    <>
   
    <Dashboard
      tab={tab}
      onSelectTab={handleSelectTab}
      onSearch={handleSearch}
      licenceData = {licenceData}
      togglePayment={togglePayment}
      paymentDone = {paymentDone}
    />
    {/* my button to add product */}
     {/* <button onClick = {addProductTable}> product </button> */}
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
  isLoading: state.loader.isLoading,

});
const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (payload) => dispatch(actions.setLoading(payload)),
  };
};
export default compose(
  withFirebase,
  connect(mapStateToProps,mapDispatchToProps)
)(DashboardContainer);
// export default DashboardContainer;
