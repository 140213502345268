export const downloadProducts = [
  {
    productDescription: "Download CA MAX Setup Ver.3.0",
    singleUserLinkText: "Single User",
    singleuserUrl:
      "https://firebasestorage.googleapis.com/v0/b/camax-81a85.appspot.com/o/Downloads%2FsetupSU%203.0.zip?alt=media&token=a234fa46-cdfd-439c-bd3c-ea92c75df8b2",
    multiuserLinkText: "Multiple User",
    multiUserUrl:
      "https://firebasestorage.googleapis.com/v0/b/camax-81a85.appspot.com/o/Downloads%2FsetupMU%203.0.zip?alt=media&token=8f9657cb-6dcf-4100-9663-71a258788b9c",
  },
  {
    productDescription: "Download CA MAX Setup Ver.4.0",
    singleUserLinkText: "Single User",
    singleuserUrl:
      "https://firebasestorage.googleapis.com/v0/b/camax-81a85.appspot.com/o/Downloads%2FsetupSU%204.0.zip?alt=media&token=ec36d0e6-df88-4f71-b8c1-eabceec00b80",
    multiuserLinkText: "Multiple User",
    multiUserUrl:
      "https://firebasestorage.googleapis.com/v0/b/camax-81a85.appspot.com/o/Downloads%2Fsetup%20MU%204.0.zip?alt=media&token=1c3e47c4-21de-473c-a378-a1b5cae5e196",
  },
];

export const appLinks = {
  android: "https://play.google.com/store/apps/details?id=com.camax.app",
  ios: "https://apps.apple.com/in/app/ca-max-gst-accounting/id1556994082",
};
