import React from "react";
import { Button } from "reactstrap";

import logo from "../../assests/images/logo192.png";

const PageNotFound = ({ onClick }) => {
  return (
    <div
      className={"text-center d-flex align-items-center justify-content-center"}
      style={{
        minHeight: "90vh",
      }}
    >
      <div>
        <img
          src={logo}
          alt="camax"
          className="mb-4"
          style={{ height: 46 }}
        ></img>
        <h2 className="mb-4">Sorry, requested page not found!!</h2>
        <Button color="primary" className="px-4 py-2" onClick={onClick}>
          Go To Home
        </Button>
      </div>
    </div>
  );
};

export default PageNotFound;
