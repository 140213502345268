const Products = {
  ca_max: {
    id: "ca_max",
    name: "CA MAX",
    count: "01",
    features: [
      "Complete Accounting System for all Businesses: Manufacturers, Traders, Institutes, Professionals Etc..",
      "Latest and Time-to-Time Amended E-Filing System, Very Easy and User Friendly Return Making Modules.",
      "Invoicing & Inventory Systems for All Manufacturers, Traders, Institutes, Professionals. Customized Billing.",
      "Pharma Trade Billing & Inventory.",
      "Barcode Based Retail Counter POS Billing.",
      "Customer Based Invoice Format Designing.",
      "Option of Multiple (Any Number of) Invoice Designs and page layouts.",
      "Design your Own Formats. ",
      "Company/Brand Logos on Invoice Formats.",
    ],
    isShowImageFirst: true,
  },
  pay_max: {
    id: "pay_max",
    name: "Pay MAX",
    count: "02",
    features: [
      "PayRoll Solution with E-Filing of EPF/ESI Returns. ",
      "Make own Salary Structure.",
      "Multi-Company Salary Registers and Structures",
    ],
    isShowImageFirst: false,
  },
  ca_ad_max: {
    id: "ca_ad_max",
    name: "CA AD MAX",
    count: "03",
    features: [
      "Advertising Agency Management System from Release Order to Client Billing,",
      "Publication Billing, Scheduling. Tariff Cards, Accounts, Cr.Notes,",
      "Insertion-Wise Profits, Publication/Client Payment Outstanding System.",
    ],
    isShowImageFirst: true,
  },
};

export default Products;
