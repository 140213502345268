import React from "react";
import { withRouter } from "react-router-dom";

const Support = (props) => {
  const {history} = props
  const resirectTo = (navigateTo)=>{
    history.push(navigateTo)
  }
  return (
  <div className="footer-detail">
    <h3>Support</h3>
    {/* <p onClick = {()=>{resirectTo('/downloads')}}>Downloads</p> */}
    <p onClick = {()=>{resirectTo("/job-seekers")}}>JobSeekers</p>
    <p onClick = {()=>{resirectTo("/employers")}}>Employers</p>
   
  </div>
)};
export default withRouter(Support)

// export default Support;
