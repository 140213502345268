const inputFormFields = {
  username: { 
    id: "username", label: "Full Name", type: "text", col: 6,isRequired:true },
  companyName: {
    id: "companyName",
    label: "Company Name",
    type: "text",
    col: 6,
    isRequired:false
  },
  address: { id: "address", label: "Address", type: "text", col: 8 ,isRequired:true},
  city: { id: "city", label: "City", type: "text", col: 4 ,isRequired:true},
  state: { id: "state", label: "State", type: "text", col: 4,isRequired:true },
  country: { id: "country", label: "Country", type: "text", col: 4 ,isRequired:true},
  gstInNumber: {
    id: "gstInNumber",
    label: "GSTIN No.",
    type: "text",
    col: 4,
    isRequired:false
  },
  phone: { id: "phone", label: "Phone", type: "text", col: 6 ,isRequired:true},
  email: { id: "email", label: "Email ID", type: "email", col: 6 ,isRequired:true},
  password: { id: "password", label: "Password", type: "password", col: 6,isRequired:true },
  confirmPassword: {
    id: "confirmPassword",
    label: "Confirm Password",
    type: "password",
    col: 6,
    isRequired:true,
  },
};

export default inputFormFields;
