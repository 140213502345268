import React from "react";
import { Button, Input } from "reactstrap";

import AccountDetails from "../../../../components/accountDetails";
import CameraIcon from "../../../../assests/images/accounts/photo-camera.svg";
import User from "../../../../assests/images/dashboard/user.png";

const Profile = ({ values, onChange, onChangeProfilePicture, onSave }) => (
  <div className="dashboard-content">
    <h2>Account Information</h2>
    <div>
      {/* <div onClick={onChangeProfilePicture}>
        <img src={""} alt="user" />
      </div> */}
      <div
        className="register-form"
        style={{ maxWidth: "720px", margin: "0 auto" }}
      >
        <div className="update-profile-image" onClick={onChangeProfilePicture}>
          <img
            id="output"
            src={User}
            alt="profile"
            className="profile negative-margin"
          />
          {/* <Input type="file" id="f-upload" name="myImage" className="d-none" />
          <label htmlFor="f-upload" class="custom-file-upload">
            <div className="camera-btn">
              <img src={CameraIcon} alt="camera" />
            </div>
          </label> */}
        </div>
        <AccountDetails values={values} onChange={onChange} isEditProfile = {true} />
        <div className="text-center my-4">
          <Button color="theme" onClick={onSave} className="register-btn">
            Save
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default Profile;
