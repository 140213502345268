export const VERSIONS = {
  CAMAX: {
    currentVersion: "V 11.0",
    currentUrl:
      "https://firebasestorage.googleapis.com/v0/b/camax-81a85.appspot.com/o/Downloads%2FCA%20MAX%20GST%2011.0.zip?alt=media&token=https://firebasestorage.googleapis.com/v0/b/camax-81a85.appspot.com/o/Downloads%2FCA%20MAX%20GST%2011.0.zip?alt=media&token=507c9bf0-c096-432d-821d-d3c9e9cbd317",
    previousVersion: "V 10.0 h",
    previousUrl:
      "https://firebasestorage.googleapis.com/v0/b/camax-81a85.appspot.com/o/Downloads%2FCA%20MAX%20GST%2010.0%20h.zip?alt=media&token=f452b087-fcac-43b7-a129-566eda8be616",

//      previousVersion: "V 9.0 e",
//    previousUrl:
//      "https://firebasestorage.googleapis.com/v0/b/camax-81a85.appspot.com/o/Downloads%2FCA%20MAX%20GST%209.0E.Zip?alt=media&token=b5d86486-9c1d-4a67-b5cf-40086e205768",

      // previousToPreviousVersion : 'V 8.0',
    // previousToPreviousUrl : ''
  },
};
export const RELEASENOTES = {
  CAMAX: {
    notes: [
      "E-Invoicing Automatic with Invoice generation thru API and JSON (Both methods)",
      "E-Way Bill Automatic with Invoice generation thru API and JSON (Both methods)",
      "Generate Invoice thru Quotation",
      "R2B Auto Matching with Books thru API and JSON (Both Methods)",
      "3B downloading from Portal thru API with full view of Monthly/Quarterly",
      "GST R1 update regarding HSN Summary",
      "Invoice Furnishing Facility (IFF) for QRMP Dealers",
      "TCS Charging system",
      "Self-Selection of TCS Applicability and Auto-Selection based on Turnover (Both Methods)",
      "TCS Report for Return purpose",
      "Compulsory and Auto-Backup System",
      "Auto E-mail Invoice to Customer",
      "Auto PDF Generation of Invoices",
      "Search/Edit of Invoices while making Invoice",
    ],
  },
};
