import React from "react";
import cx from "classnames";

import Tabs from "./data";
import Dashboard from "../../../../assests/images/dashboard/dashboard.png";
import Editprofile from "../../../../assests/images/dashboard/editprofile.png";
import Setting from "../../../../assests/images/dashboard/setting.png";
import SettingRed from "../../../../assests/images/dashboard/settingRed.png";
import DashboardRed from "../../../../assests/images/dashboard/dashboardRed.png";
import EditProfileRed from "../../../../assests/images/dashboard/editProfileRed.png";

const images = {
  dashboard: [Dashboard, DashboardRed],
  editProfile: [Editprofile, EditProfileRed],
  settings: [Setting, SettingRed],
};

const Sidebar = ({ selected = "dashboard", onSelect = () => {} }) => (
  <div className="sidebar-content">
    <ul className="sidebar-list">
      {Object.keys(Tabs).map((key) => {
        const value = Tabs[key];
        return (
          <li key={key} onClick={() => onSelect(key)}>
            <a
              href="#"
              className={cx({
                active: key === selected,
              })}
            >
              <span className="menu-icon">
                <img src={images[key][0]} className="img-1" alt="dashboard" />
                <img
                  src={images[key][1]}
                  className="img-2"
                  alt="dashboard-active"
                />
              </span>
              {value.label}
            </a>
          </li>
        );
      })}
    </ul>
  </div>
);

export default Sidebar;
