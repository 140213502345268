import React from "react";
import { Col, Container, Row } from "reactstrap";
import carrierPng from "../../../assests/images/carrier/carrier.png";

function AboutCompany(props) {
  return (
    <section className="about-us-wrapper">
      <Container>
        <Row>
          <Col xl={6}>
            <div className="founder-image">
              {/* <img src={carrierPng} alt="carrierPng" /> */}
              <div className="founder-detail">
                <span>
                  {/* <h4 className="mb-0">Suresh Suri </h4> */}
                  {/* <p className="mb-0">Founder</p> */}
                </span>
              </div>
            </div>
          </Col>
          <Col xl={6}>
            <div className="founder-info">
              <h3> About us</h3>
              <p>
                {" "}
                Microsys & Equipments have been a part of transforming the digital world since 1989. We are an ISO 9001-2008 certified company. As one of the leading Software development company, CA MAX has been a part of thousands of companies from prominent industries, Trading houses, Accounts Professionals, Govt. Offices, Institutes and all sorts of Business Houses.
              </p>
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and industry.
              </p> */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AboutCompany;
