import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import React from "react";
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyCs_OJTcGMLlrSLz2VozERbeU2d5U8aOHU&libraries=places")
export class MapContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      lat : undefined,
      lng : undefined
    }
  }
  componentDidMount() {
    const  address  = "DSS - 147 P, Sector - 11, Panchkula- Haryana -134109, India"
    Geocode.setApiKey("AIzaSyCs_OJTcGMLlrSLz2VozERbeU2d5U8aOHU");
    Geocode.enableDebug();
    Geocode.fromAddress(
      `${address}`
    ).then(
      (response) => {
        this.setState({
          lat: response.results[0].geometry.location.lat,
          lng: response.results[0].geometry.location.lng,
        });
      },
      (error) => {}
    );
  }
  render() {
    const containerStyle = {
      width: "calc(100% - 40%)",
      height: "616px",
    };
    const {lat,lng}=this.state
    return (
      <Map
        containerStyle={containerStyle}
        google={this.props.google}
        initialCenter={{
          lat: lat,
          lng: lng,
        }}
        center={{
          lat: lat,
          lng: lng,
        }}
        fullscreenControl={false}
        mapTypeControl={false}
        streetViewControl={false}
        zoom={15}
        onClick={this.onMapClicked}
      >
        <Marker
          onClick={this.onMarkerClick}
          name={"Current location"}
          position={{ lat: lat, lng: lng }}
        />
        <InfoWindow onClose={this.onInfoWindowClose}></InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCs_OJTcGMLlrSLz2VozERbeU2d5U8aOHU&libraries=places",
})(MapContainer);
