import React from 'react';
import {
  Row,
  Input,
  Label,
  Modal,
  ModalBody,
  FormGroup,
  Col,
  Button,
  ModalHeader
} from "reactstrap";
import "./verifyemailmodal.scss"
import closeIcon from "../../assests/images/close.svg";

function VerifyEmail({ email, resendEmail, isOpen, toggle }) {
  return (
    <Modal isOpen={isOpen} centered className="verify-email-modal">
     <button className="closebtn" onClick={toggle}>
          <img src={closeIcon} alt="close" />
        </button>
      <ModalHeader>
      
        Verify Email Account
         </ModalHeader>
      <ModalBody d-flex align-items-center justify-content-cente>
      
       <p> we sent an email to <span>{email || "devInnow8@gmail.com"}</span> for verify your account</p>
        <p>Didn`t get email ?</p>
        <Button onClick={resendEmail} className="btn-theme"> Resend </Button>
      </ModalBody>
    </Modal>
  );
}

export default VerifyEmail;