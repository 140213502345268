import React from "react";
import { Container, Col, Row } from "reactstrap";

import Details from "./components/detail";
import Company from "./components/company";
import Products from "./components/products";
import Support from "./components/support";
import ContactUs from "./components/contactUs";
import PrivacyPolicy from "./components/privacyPolicy";
const Footer = () => (
  <section className="footer-wrapper">
    <Container>
      <Row>
        <Col>
          <Details />
        </Col>
        <Col>
          <Company/>
        </Col>
        <Col>
          <Products />
        </Col>
        <Col>
          <Support />
        </Col>
        <Col>
          <ContactUs />
        </Col>
        <Col>
          <PrivacyPolicy />
        </Col>
      </Row>
    </Container>
  </section>
);

export default Footer;
