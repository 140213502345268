import { SET_USER, UPDATE_USER } from "../actions";
import { composeResetReducer } from "./reset";
import { ROLES } from "../../shared/constants";

const initialState = {
  uid: "",
  username: "",
  companyName: "",
  address: "",
  city: "",
  state: "",
  country: "",
  gstInNumber: "",
  email: "",
  role: ROLES.USER,
  photoUrl: "",
  isLogin: false,
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.payload,
        isLogin: true,
      };

    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default composeResetReducer(UserReducer, initialState);
