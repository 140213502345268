import React from "react";
import { withRouter } from "react-router-dom";

import PaymentSystem from "./component";

const PaymentSystemContainer = () => {
  return <PaymentSystem />;
};

export default withRouter(PaymentSystemContainer);
