import React from "react";
import DownloadMobile from "../../../assests/images/downloads/phones-version.png";
import { Container, Row, Col, Button } from "reactstrap";
import {RELEASENOTES} from '../../../shared/utils/stringConstants.js'
function UpdationFeatures(props) {
  return (
    <section className="version-updation-wrapper">
      <div className="version-updation">
        <Container>
          <Row>
            <Col md={12} className="text-center">
              <h4 className="custom-title">
                <span className="text-contain">
                  <span className="text">CA MAX </span>
                  <span className="backText">CA MAX</span>
                </span>
              </h4>
            </Col>

            <Col md={6}>
              <div className="mb-4 mb-md-0">
                <img src={DownloadMobile} className="img-fluid" alt="banner" />
              </div>
            </Col>
            <Col md={6}>
              <h5>Release Notes:</h5>
              <ul>
                {RELEASENOTES && RELEASENOTES.CAMAX &&
                  RELEASENOTES.CAMAX.notes.map((feature) => {
                    return <li> {feature}</li>;
                  })}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        {/* <Row>
          <Col md={12} className="text-center">
            <h4 className="custom-title">
              <span className="text-contain">
                <span className="text">Pay Max </span>
                <span className="backText">Pay Max</span>
              </span>
            </h4>
          </Col>
          <Col md={6} className="d-md-none">
            <div className="mb-4 mb-md-0">
              <img src={DownloadMobile} className="img-fluid" alt="banner" />
            </div>
          </Col>

          <Col md={6}>
            <h5>Ver 16.7 (V) Updation Contains:</h5>
            <ul>
              {updationFeaturesCAMAX &&
                updationFeaturesCAMAX.map((feature) => {
                  return <li> {feature}</li>;
                })}
            </ul>
          </Col>
          <Col md={6} className="d-md-block d-none">
            <div>
              <img src={DownloadMobile} className="img-fluid" alt="banner" />
            </div>
          </Col>
        </Row> */}
        {/* <Row className="mt-5">
          <Col md={12} className="text-center">
            <h4 className="custom-title">
              <span className="text-contain">
                <span className="text">CD AD MAX</span>
                <span className="backText">CD AD MAX</span>
              </span>
            </h4>
          </Col>

          <Col md={6}>
            <div className="mb-4 mb-md-0">
              <img src={DownloadMobile} className="img-fluid" alt="banner" />
            </div>
          </Col>
          <Col md={6}>
            <h5>Ver 16.7 (V) Updation Contains:</h5>
            <ul>
              {updationFeaturesCAMAX &&
                updationFeaturesCAMAX.map((feature) => {
                  return <li> {feature}</li>;
                })}
            </ul>
          </Col>
        </Row> */}
      </Container>
    </section>
  );
}

export default UpdationFeatures;
