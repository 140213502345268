import React from "react";
import { Container, Row, Col } from "reactstrap";
import features from "../../../../assests/images/home/features.png";
import phoneFeatures from "../../../../assests/images/home/phone-features.png";

const FeatureContainer = () => {
  return (
    <>
      <section className="features-wrapper">
        <Container>
          <div className="features-wrap">
            <h4 className="custom-white-title">
              <span className="text-contain">
                <span className="text">Features of CA MAX</span>
                <span className="backText">Features</span>
              </span>
            </h4>
            <Row className="align-items-center features-content">
              <Col lg={8}>
                <div className="d-lg-none feature-mobile-img text-center">
                  <img src={features} alt="features" className="img-fluid" />
                </div>

                <div className="features-text">
                  <h1>
                    <span>01</span>
                  </h1>
                  <h3>Multi Company System (Can Create any number of Companies)</h3>
                  {/* <p>
                  CA Max offers you a secured login to your account using your Gmail Account. 
                  </p> */}
                </div>
                <div className="features-text ml-lg-auto">
                  <h1>
                    <span>02</span>
                  </h1>
                  <h3>Customer Based Invoice Format Designing.</h3>
                  {/* <p>
                  To secure the application and data, CA Max offers 2 step authentication which includes email authentication and OTP verification which will be sent to the registered mobile number. 
                  </p> */}
                </div>
              </Col>
              <Col lg={4} className="d-lg-block d-none">
                <div>
                  <img src={features} alt="features" className="img-fluid" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="features-content-wrapper">
        <Container>
          <Row className="align-items-center features-content">
            <Col md={4}>
              <div className="phone-features">
                <img src={phoneFeatures} alt="features" className="img-fluid" />
              </div>
            </Col>
            <Col md={8}>
              <div className="features-text ml-lg-auto">
                <h1>
                  <span>03</span>
                </h1>
                <h3>User Friendly GST Return System</h3>
                {/* <p>
                Using our CA max Mobile app our users can get quick access to the financial records of all their companies in an hassle-free way.
                </p> */}
              </div>
              <div className="features-text">
                <h1>
                  <span>04</span>
                </h1>
                <h3>E-Way Billing and E-Invoicing with Bulk Generation System</h3>
                {/* <p>
                Our user-friendly interface will help your read every accounts and the related transactions. Using CA Max you can easly filter transaction history and download them as sharable pdf files.
                </p> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default FeatureContainer;
