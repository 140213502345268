import React from "react";
import { Button, Card, CardBody, Container, Row, Col, Input } from "reactstrap";

import AccountDetails from "../../components/accountDetails";
import ArrowLeft from "../../assests/images/contact/arowleft.png";
import CameraIcon from "../../assests/images/accounts/photo-camera.svg";
import User from "../../assests/images/dashboard/user.png";

const SignUp = ({ values, onChange, onSignUp, onToggleAuthMode }) => {
  return (
    <div className="register-wrapper">
      <Container>
        <Row>
          <Col md={6}>
            <div className="register-content">
              {/* <h3>Lorem Ipsum is simply Lorem</h3> */}
              <h2>
                Sign up now to manage your  <span>licences and AMC</span>  
              </h2>
              <p>
              We will notify you about new offers and updates on the products and services offered by CA MAX. Fill up your details and never again miss anything. 
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div className="register-form">
              <Card>
                <CardBody>
                  <h3>
                    <span>Register yourself with CA MAX</span>
                  </h3>
                  <div>
                    <div className="update-profile-image">
                      <img
                        id="output"
                        src={User}
                        alt="profile"
                        className="profile negative-margin"
                      />
                      {/* <Input
                        type="file"
                        id="f-upload"
                        name="myImage"
                        className="d-none"
                      />
                      <label htmlFor="f-upload" class="custom-file-upload">
                        <div className="camera-btn">
                          <img src={CameraIcon} alt="camera" />
                        </div>
                      </label> */}
                    </div>
                    <AccountDetails values={values} onChange={onChange} />
                    <div className="text-center pt-4">
                      <Button
                        onClick={onSignUp}
                        color="theme"
                        className="register-btn"
                      >
                        Register <img src={ArrowLeft} className="ml-2" />
                      </Button>
                      <p className="mt-2 signup-link">
                        Already registered?{" "}
                        <span onClick={onToggleAuthMode}>Sign In</span>
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignUp;
