import React, { useCallback, useState } from "react";
import ComingSoon from "./component";
import { withFirebase } from "../../../../firebase";
import { compose } from "redux";
import Alert from "../../../../shared/utils/alert"
import {validatePhone} from "../../../../shared/utils/validations";
import * as actions from "../../../../redux/actions";
import { connect } from "react-redux";

const ComingSoonContainer = (props) => {
  const {firebase,setLoading} = props
  const [mobileNumber, setMobileNumber] = useState("");

  const handleChange = useCallback(
    (event) => {
      setMobileNumber(event.target.value);
    },
    [mobileNumber]
  );

  const handleSubsciption = async ()=>{
   let data = {phone:mobileNumber}
   if(!mobileNumber){
    Alert(400,"Please enter your phone number!!")
   }
   if(!validatePhone(mobileNumber)){
    Alert(400, "Please enter valid phone number!!");
    return;
  }
  setLoading(true)
   await firebase.addSubscribers(data,(res)=>{
     if(res){
      Alert(200, "Subscribed successfully !!");
      setLoading(false)
     }
   })
   setMobileNumber('')
  }

  return <ComingSoon value={mobileNumber} onChange={handleChange} handleSubsciption = {handleSubsciption} />;
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loader.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (payload) => dispatch(actions.setLoading(payload)),
  };
};
export default compose(withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(ComingSoonContainer);

// export default compose(withFirebase
// )(ComingSoonContainer);
// export default ComingSoonContainer;
