import React from "react";
import { Card, CardBody } from "reactstrap";

import SignIn from "../signIn";
import SignUp from "../signUp";
import Dashboard from "../dashboard";

const ManageLicence = ({ isAuthenticated, isRegister, onToggleAuthMode }) => {
  return (
    <div>
      {isAuthenticated ? (
        <Dashboard />
      ) : (
        <>
          {isRegister ? (
            <SignUp onToggleAuthMode={onToggleAuthMode} />
          ) : (
            <SignIn onToggleAuthMode={onToggleAuthMode} />
          )}
        </>
      )}
    </div>
  );
};

export default ManageLicence;
