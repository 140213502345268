import React, { useState,useEffect} from "react";
import { connect } from "react-redux";
import AccountDetails from "./component";

const userDetail = {
  username: "",
  companyName: "",
  address: "",
  city: "",
  state: "",
  country: "",
  gstInNumber: "",
  email: "",
  password: "",
  confirmPassword: "",
  phone : ""
};

const AccountDetailsContainer = ({ values,user,isEditProfile, onChange = () => {} }) => {
  
  const [fields, setFields] = useState(values || userDetail);

  useEffect(()=>{
    if(values){
      setFields(values)
    }
  },[values])
  const handleChange = (event) => {
    const key = event.target.id;
    const value = event.target.value;
    const updatedState = {
      ...fields,
      [key]: value,
    };

    setFields(updatedState);
    onChange(updatedState);
  };
  return <AccountDetails values={fields} onChange={handleChange} isEditProfile = {isEditProfile} />;
};
const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps)(AccountDetailsContainer)

// export default AccountDetailsContainer;
