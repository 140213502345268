import React from "react";
import ContactForm from "./components/form";
import ContactDetails from "./components/details";
import { Col, Container, Row } from "reactstrap";
import ContactUsImage from "../../assests/images/contact/contactusImage.png";
import MapContainer from "./components/googlemap/index.js";
import GoogleMapComponent from "./googleMapComponent.js";
const ContactUs = () => {
  return (
    <>
      <section className="contact-form-wrapper">
        <Container>
          <Row>
            <Col lg={7}>
              <ContactDetails />
              <ContactForm />
            </Col>
            <Col lg={5} className="d-xl-block d-none">
              <img src={ContactUsImage} alt="" className="contact-image" />
            </Col>
          </Row>
        </Container>
      </section>
      <GoogleMapComponent />
    </>
  );
};

export default ContactUs;
