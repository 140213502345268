import React from "react";
import { Row, Col, Card, CardBody, Table, Button } from "reactstrap";
import Window from "../../../../assests/images/dashboard/window.png";
import LicenceModal from "../licenceModal";
import moment from 'moment'
import { connect } from "react-redux";
import Payment from '../../../../components/payment'

const DashboardContent = ({ isOpenLicenceModal, onToggleLicenceModal,onBuyLicence,licenceData,togglePayment,paymentDone,user}) => 
{
  const totalLicence = licenceData && licenceData.length || 0
  const activeLicence = licenceData && licenceData.filter((data)=>{
    const currentDate= moment(new Date()) 
    const expiryDate = moment(data.expiryDate)
    return (expiryDate>currentDate)
  })
  const expiredLicences = licenceData && licenceData.filter((data)=>{
    const currentDate= moment(new Date()) 
    const expiryDate = moment(data.expiryDate)
    return (expiryDate<currentDate)
  })
 
  return (
  <div className="dashboard-content">
    <div className="d-flex justify-content-between align-items-center mb-3">
      <h2 className="mb-0">License Key Details</h2>
      <Button
        color="theme"
        onClick={onToggleLicenceModal}
        className="buy-license-btn"
      >
        Buy New Licence
      </Button>
     
    </div>

    <Row>
      <Col md={4}>
        <Card className="license-card">
          <CardBody className="bg-pink">
            <div className="mt-4">
              <h4>{totalLicence}</h4>
              <p className="mb-0">Total License Purchased</p>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col md={4}>
        <Card className="license-card">
          <CardBody className="bg-green">
            <div className="mt-4">
              <h4>{activeLicence && activeLicence.length || 0}</h4>
              <p className="mb-0">Active License</p>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col md={4}>
        <Card className="license-card">
          <CardBody className="bg-blue">
            <div className="mt-4">
              <h4>{expiredLicences && expiredLicences.length || 0}</h4>
              <p className="mb-0">License not in use</p>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row className="mt-4 license-key-table">
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="license-key">
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>License key</th>
                    <th>Product</th>
                    <th>Active</th>
                    <th>Expiry</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    licenceData && licenceData.map(data=>{
                     const date = new Date(data.expiryDate)
                     const substractMonth = date.getMonth() -1
                     const newDate = new Date(date.setMonth(substractMonth))
                     let showRenew = moment(newDate) < moment(new Date)
                    //  console.log("newDatenewDatenewDate=",newDate)
                    
                    return  ( <tr>
                      <td>
                        <div>{data.id}</div>
                      </td>
                      <td>{data.productName}</td>
                      <td>
                        <div className="active-info">
                          <img src={Window} />
                          <p className="mb-0">Windows</p>
                          <Button color="icon">Sign Out</Button>
                        </div>
                      </td>
                      <td>{data.expiryDate ? moment(data.expiryDate).format('DD-MMM-YYYY'):''}
                      
                     { showRenew && <Payment userId={user.uid} productId={data.id} productName = {data.productName}
                      paymentAmount={data.price} userName= {user.username} togglePayment = {togglePayment} buttonText = "Renew Now" isRenew = {true} licenceNo= {data.id} oldExpiryDate = {data.expiryDate} />}
                      </td>
                    </tr>)
                    })
                  }
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <LicenceModal isOpen={isOpenLicenceModal} onToggle={onToggleLicenceModal} onBuyLicence = {onBuyLicence} togglePayment = {togglePayment}
    paymentDone = {paymentDone} />
  </div>
)};

const mapStateToProps = (state) => ({
  user: state.user,
  isLoading: state.loader.isLoading,
});

export default connect(mapStateToProps)(DashboardContent);

// export default DashboardContent;
