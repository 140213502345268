import React from "react";
import { Container } from "reactstrap";
import DescriptionContainer from "./components/description";
import Details from "./components/details";
import UpdationFeatures from "./components/updationFeatures";
const Downloads = () => {
  return (
    <>
      <DescriptionContainer />
      <Details />
      {/* <UpdationFeatures /> */}
    </>
  );
};

export default Downloads;
