import React from "react";
import { Container } from "reactstrap";
import cx from "classnames";

import logo from "../../assests/images/logo.png";
import SubHeader from "./components/subHeader";

const Header = ({ isSticky, isTransparent }) => {
  return (
    <header
      className={cx({
        "sticky-top": isSticky,
        "transparent-header": !isSticky && isTransparent,
      })}
    >
      <Container>
        <div className="d-flex align-items-center justify-content-between">
          <div className="logo">
            <img src={logo} alt="camax" />
            <p className = "poweredBy"> Powered By <br/>Microsys & Equipments</p>
          </div>
          <SubHeader />
        </div>
      </Container>
    </header>
  );
};

export default Header;
