import Name from "../../../../assests/images/contact/phone-number.png";
import Email from "../../../../assests/images/contact/email.png";
import Profile from "../../../../assests/images/contact/profile.png";

const formFields = {
  section1: {
    name: {
      id: "name",
      label: "Your Name",
      type: "text",
      fieldType: "input",
      icon: Name,
    },
    email: {
      id: "email",
      label: "Mail",
      type: "email",
      fieldType: "input",
      icon: Email,
    },
    phone: {
      id: "phone",
      label: "Phone",
      type: "text",
      fieldType: "input",
      icon: Profile,
    },
  },
  section2: {
    message: {
      id: "message",
      label: "Message",
      type: "textarea",
      fieldType: "input",
    },
  },
  section3: {
    serviceType: {
      id: "serviceType",
      label: "Services",
      type: "option",
      list: ["CA Max", "Pay Max", "CD AD Max", "Others"],
    },
  },
};

export default formFields;
