import React from "react";
import {
  Container,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  InputGroup,
} from "reactstrap";
import formFields from "./formData";
import Arrowleft from "../../../../assests/images/contact/arowleft.png";

const ContactForm = ({ values, onChange, onSend, onSelect }) => {
  return (
    <div className="contact-us-form pt-2">
      <Container>
        <Card>
          <CardBody>
            <Row>
              <Col md={12}>
                <h2>Contact Form</h2>
              </Col>

              {Object.keys(formFields).map((sectionKey) => {
                const sectionFields = formFields[sectionKey];
                return sectionKey === "section2" && sectionFields.message ? (
                  <Col md={5}>
                    <FormGroup>
                      <label>{sectionFields.message.label}</label>
                      <Input
                        value={values["message"]}
                        type={sectionFields.message.type}
                        onChange={onChange}
                        id={sectionFields.message.id}
                      />
                    </FormGroup>
                  </Col>
                ) : sectionKey === "section1" ? (
                  <Col md={7}>
                    {Object.keys(sectionFields).map((key) => {
                      const field = sectionFields[key];
                      return (
                        <FormGroup>
                          <label>{field.label}</label>
                          <InputGroup
                            className={`${field.icon ? "icon-group" : ""}`}
                          >
                            <Input
                              value={values[key]}
                              type={field.type}
                              onChange={onChange}
                              id={field.id}
                            />
                            <img src={field.icon} />
                          </InputGroup>
                        </FormGroup>
                      );
                    })}
                  </Col>
                ) : (
                  <Col md={12}>
                    <label>{sectionFields.serviceType.label}</label>
                    <div className="services-wrapper">
                      <Row>
                        {sectionFields.serviceType.list.map((val) => (
                          <Col md={6} xl={3}>
                            <Button
                              onClick={() => onSelect("serviceType", val)}
                            >
                              {val}
                            </Button>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </Col>
                );
              })}
              <Col md={12} className="text-center send-msg">
                <Button onClick={onSend} color="theme">
                  Send Message
                  <img src={Arrowleft} className="ml-3" />
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default ContactForm;
