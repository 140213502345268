import React, { Component } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import Header from "../../components/header";
import Footer from "../../components/footer";

class Layout extends Component {
  render() {
    const { children, transparentHeader = false } = this.props;
    return (
      <div className={"root"}>
        <Header transparentHeader={transparentHeader} />
        <main className={"main"}>{children}</main>
        <Footer />
      </div>
    );
  }
}

export default compose(withRouter)(Layout);
