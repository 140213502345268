import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Container,
  Row,
  Col,
} from "reactstrap";
import ArrowLeft from "../../assests/images/contact/arowleft.png";

const ForgotPassword = ({
  email,
  newpassword,
  confirmPassword,
  onChange,
  handleForgotoPassword,
  onToggleAuthMode,
}) => {
  return (
    <div className="register-wrapper">
      <Container>
        <Row>
          <Col md={12}>
            <div className="register-form login-form">
              <Card>
                <CardBody>
                  <div>
                    <center>
                      <h2>Forget Password</h2>
                    </center>
                    <FormGroup>
                      <Label>Enter Your Email</Label>
                      <Input
                        value={email}
                        type={"text"}
                        onChange={onChange}
                        name={"email"}
                        id={"email"}
                      />
                    </FormGroup>
                    {/* <FormGroup>
                      <Label>New Password</Label>
                      <Input
                        value={newpassword}
                        type={"password"}
                        onChange={onChange}
                        name={"newpassword"}
                        id={"newpassword"}
                      />
                    </FormGroup> */}
                    {/* <FormGroup>
                      <Label>Confirm Password</Label>
                      <Input
                        value={confirmPassword}
                        type={"password"}
                        onChange={onChange}
                        name={"confirmPassword"}
                        id={"confirmPassword"}
                      />
                    </FormGroup> */}
                    <div className="text-center pt-4">
                      <Button
                        onClick={handleForgotoPassword}
                        color="theme"
                        className="register-btn"
                      >
                        Change Password <img src={ArrowLeft} className="ml-2" />
                      </Button>
                      <p></p>
                      {/* <p className="signup-link">
                        Don't have an account?{" "}
                        <span onClick={onToggleAuthMode}>Sign Up</span>
                      </p> */}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgotPassword;
