import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import SubHeader from "./component";
import { getSelectedTab } from "./utils";

const SubHeaderContainer = ({ history }) => {
  const [selectedTab, setSelectedTab] = useState(getSelectedTab());

  

  useEffect(() => {
    window.scrollTo(0,0)
    const currentTab = getSelectedTab();
    if (selectedTab !== currentTab) {
      setSelectedTab(currentTab);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const handleSelectTab = ({ id, navigateTo }) => {
    setSelectedTab(id);
    history.push(navigateTo);
  };

  return <SubHeader selectedTab={selectedTab} onSelectTab={handleSelectTab} />;
};

export default withRouter(SubHeaderContainer);
