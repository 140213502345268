import Profile from '../../../../assests/images/commonImages/profile.png'
export const formData = {
  section1:{
    firstName: {
      id: "firstName",
      label: "First Name",
      type: "text",
      fieldType: "input",
      icon: Profile,
    },
    lasttName: {
      id: "lasttName",
      label: "Last Name",
      type: "text",
      fieldType: "input",
      icon: "",
    },
    email: {
      id: "email",
      label: "Email Address",
      type: "text",
      fieldType: "input",
      icon: "",
    },
    position: {
      id: "position",
      label: "Position Applying For",
      type: "text",
      fieldType: "input",
      icon: "",
    },
    phone: {
      id: "phone",
      label: "Phone",
      type: "text",
      fieldType: "input",
      icon: "",
    },
    about: {
      id: "about",
      label: "About",
      type: "text",
      fieldType: "input",
      icon: "",
    },
  }
}