import {
  validateEmail,
  validatePassword,
} from "../../shared/utils/validations";

export const validateFormData = (values) => {
  const { email, password } = values;

  if (!email || !password) {
    return "All fields are required!!";
  }

  if (!validateEmail(email)) {
    return "Please enter valid email address!!";
  }

  if (!validatePassword(password)) {
    return "Password should be of atleat 6 characters!!";
  }

  return "";
};

export const validateForgetPasswordForm = (values) =>{
  const {newpassword,confirmPassword,email } = values;
  if (!email) {
    return "All fields are required!!";
  }
  // if (!validatePassword(newpassword)) {
  //   return "Password should be of atleat 6 characters!!";
  // } 
  // if (newpassword.toLowerCase() !== confirmPassword.toLowerCase()) {
  //   return "Password not matched!!";
  // }
  if (!validateEmail(email)) {
    return "Please enter valid email address!!";
  }

}
