import React from "react";
import { withRouter } from "react-router-dom";

import Employers from "./component";

const EmployersContainer = () => {
  return <Employers />;
};

export default withRouter(EmployersContainer);
