import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import DownloadMobile from "../../../assests/images/downloads/download-mobile.png";
import DownloadIcon from "../../../assests/images/downloads/download-icon.png";
import { VERSIONS } from "../../../shared/utils/stringConstants.js";
// import about1 from "../../../../assests/images/home/about-1.png";
import AppleIcon from "../../../assests/images/downloads/appleicon.png";
import Playstore from "../../../assests/images/downloads/playstore.png";
import { RELEASENOTES } from "../../../shared/utils/stringConstants.js";
import { Link } from "react-router-dom";
import { appLinks } from "./constants";

const DescriptionContainer = () => {
  return (
    <div className="banner-wrapper version-updation-wrapper download-wrapper">
      <Container>
        <Row>
          <Col md={6}>
            <div className="product-content">
              <h3>{/* <span>Apps available in Android and IOS</span> */}</h3>
              <h1 style={{ width: "390px" }}>
                {/* <br/> */}
                <span className="ml-1">
                  Download Latest
                  <br /> Version of CA MAX
                </span>{" "}
              </h1>
              {/* <p>
              Enjoy accounting every now and then with CA MAX. We have created the best User Interface to make it easy for you to manage all the accounts and related transactions.
              </p> */}

              <div className="text-md-left text-center">
                <Button
                  color="theme"
                  className="download-btn"
                  onClick={() => {
                    window.open(VERSIONS.CAMAX.currentUrl, "_blank");
                  }}
                >
                  Download CA Max {VERSIONS.CAMAX.currentVersion}{" "}
                  <img src={DownloadIcon} className="ml-2" />
                </Button>
              </div>

              <div className="text-md-left text-center">
                <Button
                  color="theme"
                  className="download-btn"
                  onClick={() => {
                    window.open(VERSIONS.CAMAX.previousUrl, "_blank");
                  }}
                >
                  Download CA Max {VERSIONS.CAMAX.previousVersion}{" "}
                  <img src={DownloadIcon} className="ml-2" />
                </Button>
              </div>

              {/* <div className="text-center">
              <button color="theme" className="download-link">
                Download CA Max {VERSIONS.CAMAX.previousVersion} <img src={DownloadIcon} className="ml-2" />
              </button>
            </div> */}
              {/* <div className="text-center">
              <button color="theme" className="download-link">
                Download CA Max {VERSIONS.CAMAX.previousToPreviousVersion} <img src={DownloadIcon} className="ml-2" />
              </button>
            </div> */}
              <div className="bottom-text">
                <p className="progress-text"> DOWNLOAD CA MAX MOBILE APP </p>
                <div className="d-flex flex-md-row flex-column">
                  <a href={appLinks.android} target="blank">
                    <div className="stores-button">
                      <img src={Playstore} />
                      <div>
                        <p>GET IT ON</p>
                        <h4>Google Play</h4>
                      </div>
                    </div>
                  </a>
                  <a href={appLinks.ios} target="blank">
                    <div className="stores-button ml-md-2">
                      <img src={AppleIcon} />
                      <div>
                        <p>Download on the</p>
                        <h4>App Store</h4>
                      </div>
                    </div>
                  </a>
                </div>
                
                  
               
            
                
                
              </div>
              
            </div>
            <a className="read-link" href={"https://firebasestorage.googleapis.com/v0/b/camax-81a85.appspot.com/o/Downloads%2FIntallation%20of%20Mob%20App.pdf?alt=media&token=51601ebe-b19f-416c-af9d-d1f9519ee194"} target="blank">Read the help file to setup google drive on your computer</a> <br/>

<a className="read-link" href={"https://firebasestorage.googleapis.com/v0/b/camax-81a85.appspot.com/o/Downloads%2Finstallbackupandsync.exe?alt=media&token=f325a150-3275-4123-b647-0232d088d39c"} target="blank">Download Setup File to Install google drive</a>

          </Col>
          <Col md={6}>
            <h5>Release Notes:</h5>
            <ul>
              {RELEASENOTES &&
                RELEASENOTES.CAMAX &&
                RELEASENOTES.CAMAX.notes.map((feature) => {
                  return <li> {feature}</li>;
                })}
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DescriptionContainer;
