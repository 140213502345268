import React from "react";
import { Modal } from "reactstrap";
import { connect } from "react-redux";

export const BasicLoader = () => (
  <div className="lds-roller">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

const Loader = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div className="m-loader">
      <Modal isOpen={true} className="m-loader modal-dialog-centered">
        <BasicLoader />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loader.isLoading,
  };
};

export default connect(mapStateToProps)(Loader);
