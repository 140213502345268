import React, { useState } from "react";
import EmployerForm from "./components/form/index";
import Alert from "../../shared/utils/alert";
import RecentPosts from "./components/recentPosts";
import { withFirebase } from "../../firebase";
import { compose } from "redux";
import { Row, Col, Container } from "reactstrap";
import { validateEmail, validatePhone } from "../../shared/utils/validations";
import CarrierBannerImg from "../../assests/images/recruiters/employer.png";
import * as actions from "../../redux/actions";
import { connect } from "react-redux";
const formDetail = {
  companyName: "",
  email: "",
  phone: "",
  jobTitle: "",
  experience: "",
  ctc: "",
  description: "",
};
const Employers = (props) => {
  const [fields, setFields] = useState(formDetail);
  const { firebase,setLoading } = props;
  const handlePost = (e) => {
    e.preventDefault();
    const {
      companyName,
      email,
      phone,
      jobTitle,
      experience,
      ctc,
      description,
    } = fields;
    if (
      !companyName ||
      !email ||
      !phone ||
      !jobTitle ||
      !experience ||
      !description
    ) {
      Alert(400, "Please enter required fields!!");
      return;
    }
    if (!validateEmail(email)) {
      Alert(400, "Please enter valid email address!!");
      return;
    }
    if (!validatePhone(phone)) {
      Alert(400, "Please enter valid Phone number!!");
      return;
    }
    let data = fields;
    for (var propName in data){
      if(propName == undefined){
        delete data[propName];
      }
    }
    data = {
      ...data,
      status: "pending",
    };
    setLoading(true)
    firebase.addEmployerPost(data, (res) => {
      if (res) {
        setLoading(false)
        Alert(200, "Your requirement for an employee has been registered, It will be online shortly after Screening");
      }
    });
    setFields(formDetail);
  };
  const handleChange = (event) => {
    const key = event.target.id;
    const value = event.target.value;
    setFields((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  return (
    <div className="employee-wrapper">
      <Container>
        <Row className="align-items-center">
          <Col lg={7}>
            <EmployerForm
              onPost={handlePost}
              onChange={handleChange}
              values={fields}
            />
          </Col>
          <Col lg={5} className="d-lg-block d-none">
            <img src={CarrierBannerImg} className="img-fluid" />
          </Col>
        </Row>
        <Row>
          <Col lg={7}>
            <RecentPosts />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loader.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (payload) => dispatch(actions.setLoading(payload)),
  };
};
export default compose(withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(Employers);
// export default compose(withFirebase)(Employers);
