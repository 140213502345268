export default {
  API_URL: process.env.REACT_APP_DEV_API_URL,
  API_KEY: process.env.REACT_APP_DEV_API_KEY,
  AUTH_DOMAIN: process.env.REACT_APP_DEV_AUTH_DOMAIN,
  DATABASE_URL: process.env.REACT_APP_DEV_DATABASE_URL,
  PROJECT_ID: process.env.REACT_APP_DEV_PROJECT_ID,
  MESSAGING_SENDER_ID: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
  APP_ID: process.env.REACT_APP_DEV_APP_ID,
  STORAGE_BUCKET: process.env.REACT_APP_DEV_STORAGE_BUCKET,
  RAZOR_PAY_TEST_KEY: process.env.REACT_APP_DEV_RAZOR_PAY_TEST_KEY,
};
