
import logo from '../../../assests/images/logo.png'
export const VerifyEmailTemplate = ({link}) =>{
  return (
    `
    <!doctype html>
<html lang="en">
   <head>
      <!-- Required meta tags -->
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
     
      <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap" rel="stylesheet">
      <title>verify Email</title>
      <style>
      </style>
   </head>
   <body style="background-color:#FFF1E8;font-family: 'Montserrat', sans-serif;">
      <table style="box-shadow: 0px 30px 60px #0000000d;
         border-radius: 10px;
         background-color: white;
         padding: 41px;
         max-width: 608px;
         height: 430px;
         width: 100%; text-align: center;
         margin: 0 auto;">
         <tr>
            <td><img src=${logo} style="    margin: 0px auto;
               display: table;
               margin-bottom: 40px;    height: 32px;
               width: 168px;"/></td>
         </tr>
         <tr>
            <td>
               <h6 style="font-size: 18px;line-height: 28px; font-weight: 600;    margin-top: 0;
                  margin-bottom: 29px;">Thanks for signing up for CA MAX. Please click below to confirm this is your email address.</h6>
            </td>
         </tr>
         <tr>
            <td>
               <a href=${link} style="    font-size: 14px;
                  max-width: 330px;
                  width: 100%;
                  padding: 13px 0px;
                  line-height: 18px;
                  height: 21px;
                  margin: 0 auto;
                  display: table;     background-color: #e27224;
                  border: none;
                  border-radius: 4px;letter-spacing: 0.35px;
                  color: #fff;
                  text-align: center;
                  font-weight: bold;text-decoration: none;
                  font-weight: bold;
                  letter-spacing: 0.45px;">Verify Email Address</a>
            </td>
         </tr>
         <tr>
            <td>
               <p style="font-size:14px;line-height: 18px;margin-top: 40px; margin-bottom: 28px;">P.S. In case you haven't created an account, please ignore or delete this email.</p>
            </td>
         </tr>
      </table>
   </body>
</html>
    
    
    `
  );
}

export default VerifyEmailTemplate;