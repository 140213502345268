import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";

import Tabs from "./data";

const NavBar = ({ value = "", className = "", onClick = () => {},setIsVisible }) => {
  return (
    <ul className={cx("nav-bar list-unstyled", className)}>
      {Tabs.map((tab) => (
        <li>
          <Link
            key={"tab-" + tab.id}
            className={cx("btn", { active: value === tab.id })}
            onClick={() => {
              setIsVisible(false)
              onClick({ id: tab.id, navigateTo: tab.link })
            }}
          >
            {tab.label}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default NavBar;
