import React from "react";
import Logo from "../../../../assests/images/logo.png";

const Details = () => (
  <div>
    <img src={Logo} alt="logo" className="img-fluid mb-4" />
    <p>
      Microsys & Equipments is an ISO 9001 - 2008 Certified Company Located at Panchkula (Haryana). Microsys is one of the oldest and
      leading Software Development company of this Region.
    </p>
  </div>
);

export default Details;
