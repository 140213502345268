import React from "react";
import { Container, Row, Col } from "reactstrap";
import Product1 from "../../../../assests/images/products/product-1.png";
import Product2 from "../../../../assests/images/products/product-2.png";
import Product3 from "../../../../assests/images/products/product-3.png";

export default () => (
  <section className="text-center products">
    <Container>
      <h4 className="custom-title mt-5">
        <span className="text-contain">
          <span className="text">One-Stop Solutions for your business</span>
          {/* <span className="backText">Products</span> */}
        </span>
      </h4>
      <Row>
        <Col md={12}>
          <p>
          CA MAx comes with lots of opportunities to simplify your business processes. We offer digital solutions with tons of features for accounting, payroll, and advertising management.
          </p>
          {/* <p>
            Pay MAX is an complete Payroll System with all statutary reportings
          </p> */}
        </Col>
      </Row>
      <Row className="mt-5 pt-5">
        <Col md={4}>
          <div>
            <img src={Product1} alt="banner" />
            <h3>CA MAX</h3>
            <p>
            An all in one solution for your accounting needs. Be the business of any domain, we have you covered. Our CA MAX solution today is used by thousands of Manufacturers, Traders, Professionals and Organizations.
            </p>
          </div>
        </Col>
        <Col md={4}>
          <div>
            <img src={Product2} alt="banner" />
            <h3>PayMax</h3>
            <p>
            Presenting you the most integrated payroll solution enabled with E-filing. With PayMax you can save time, money, and paper works. 
            </p>
          </div>
        </Col>
        <Col md={4}>
          <div>
            <img src={Product3} alt="banner" />
            <h3>CA AD MAX</h3>
            <p>
            With us, you can focus on planning, managing, and executing your creative ideas much faster while we take care of the data interlinking with our purely automated workflow solution. CA AD Max helps you focus more on what really matters instead of worrying about Publication Billing. Tariff Cards,  or the Accounts.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);
