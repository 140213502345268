import React from "react";
import Routes from "./routes";
import { NotificationContainer } from "react-notifications";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { configureStore } from "./redux/store";
import "react-notifications/lib/notifications.css";
import "./assests/styles/main.scss";
import Firebase, { FirebaseContext } from "./firebase";
import Loader from "./components/loader";

const { store, persistor } = configureStore();

const App = () => (
  <FirebaseContext.Provider value={new Firebase()}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
        <Loader />
        <NotificationContainer />
      </PersistGate>
    </Provider>
  </FirebaseContext.Provider>
);

export default App;
