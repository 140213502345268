import React from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Downloads from "../containers/downloads";
import Employers from "../containers/employers";
import Home from "../containers/home";
import JobSeekers from "../containers/jobSeekers";
import Layout from "../containers/layout";
import PageNotFound from "../containers/pageNotFound";
import OurProducts from "../containers/ourProducts";
import PaymentSystem from "../containers/paymentSystem";
import ContactUs from "../containers/contactUs";
import SalesPartner from "../containers/SalesPartners";
import Carrier from "../containers/carrier";
import ManageLicence from "../containers/manageLicence";
import Forgotpassword from "../containers/ForgotPassword";
import PrivacyPolocies from "../containers/privacyPolicies";
import TermsCondiitons from "../containers/termsConditions";

import { ROUTES } from "../shared/constants";
// import UserDetail from '../containers/AdminPage/components/users/components/userDetail'
const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
};

const PrivateRoutes = ({ component: Component, pageAccess, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.isAuthenticated ||
        props.location.pathname === ROUTES.manageLicences ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.home,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const NotFound = () => {
  return <Route component={PageNotFound} />;
};

const Routes = ({ user: { isLogin } }) => {
  return (
    <BrowserRouter>
      <Layout transparentHeader>
        <Switch>
          <PublicRoute
            exact
            path={ROUTES.carrier}
            component={Carrier}
            isAuthenticated={isLogin}
          />
          <PublicRoute
            exact
            path={ROUTES.contactUs}
            component={ContactUs}
            isAuthenticated={isLogin}
          />
          <PublicRoute
            exact
            path={ROUTES.downloads}
            component={Downloads}
            isAuthenticated={isLogin}
          />
          <PublicRoute
            exact
            path={ROUTES.employers}
            component={Employers}
            isAuthenticated={isLogin}
          />
          <PublicRoute
            exact
            path={ROUTES.home}
            component={Home}
            isAuthenticated={isLogin}
          />
          <PublicRoute
            exact
            path={ROUTES.jobSeekers}
            component={JobSeekers}
            isAuthenticated={isLogin}
          />
          <PublicRoute
            exact
            path={ROUTES.amcPaymentSystem}
            component={PaymentSystem}
            isAuthenticated={isLogin}
          />
          <PublicRoute
            exact
            path={ROUTES.ourProducts}
            component={OurProducts}
            isAuthenticated={isLogin}
          />
          <PublicRoute
            exact
            path={ROUTES.becomeSalesPartner}
            // component={SalesPartner}
            component={SalesPartner}
            // ContactUs
            isAuthenticated={isLogin}
          />
          <PrivateRoutes
            exact
            path={ROUTES.forgotPassword}
            component={Forgotpassword}
            isAuthenticated={isLogin}
          />
          <PrivateRoutes
            exact
            path={ROUTES.manageLicences}
            component={ManageLicence}
            isAuthenticated={isLogin}
          />
          <PublicRoute
            exact
            path={ROUTES.privacyPolicies}
            component={PrivacyPolocies}
            isAuthenticated={isLogin}
          />

          <PublicRoute
            exact
            path={ROUTES.termsConditions}
            component={TermsCondiitons}
            isAuthenticated={isLogin}
          />
          <NotFound />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(Routes);
