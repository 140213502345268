export const RESET = "RESET";

/**
 * @method resetReducer : To reset reducer state
 *
 * @param {*} params
 */
export const resetReducer = (params) => ({
  payload: { params },
  type: RESET,
});
