import baseUrl from "../baseUrl";

/**
 * @constant ROUTES : To define app routes.
 */
export const ROUTES = {
  amcPaymentSystem: "/amc-payment-system",
  becomeSalesPartner: "/become-sales-partner",
  carrier: "/carrier",
  contactUs: "/contact-us",
  downloads: "/downloads",
  employers: "/employers",
  home: "/",
  jobSeekers: "/job-seekers",
  manageLicences: "/manage-licences",
  pageNotFound: "/page-not-found",
  ourProducts: "/our-products",
  forgotPassword : '/forgotPassword',
  privacyPolicies : '/privacyPolicies',
  termsConditions : '/terms-conditions'
};

/**
 * @constant SCHEMA : To define table names in firebase realtime database.
 */
export const SCHEMA = {
  USERS: "users",
  POSTBYEMPLOYEE :"postbyemployee",
  POSTBYEMPLOYER :"postbyemployer",
  APPLICANTS : 'applicants',
  POSTBYCAMAX : 'postbycamax',
  PRODUCTS : 'products',
  SUBSCIBERS : 'subscribers',
  COUPONS : 'coupons',

};

/**
 * @constant API_URL : To define list of api urls
 */
export const API_URL = {
  verifyLicence: baseUrl + "/verifyLicence",
  paymentOrders: baseUrl + "/paymentOrders",
  paymentCapture: baseUrl + "/paymentCapture",
  sendMail: baseUrl + "/sendMail",
  confirmUser : baseUrl +"/confirmUser",
  forgotpassword: baseUrl +"/forgotPassword" ,
};

/**
 * @constant PAYMENT_STATUS : To define razor-pay payment process status
 */
export const PAYMENT_STATUS = {
  ORDER: {},
  CAPTURE: {
    SUCCESS: "success",
    FAIL: "fail",
  },
};

/**
 * @constant EMAIL_NOTIFICATION
 */
export const EMAIL_NOTIFICATION = {
  CONTACT_US_TO: "microsysindia63@gmail.com",
  FROM: "microsysindia63@gmail.com",
};

/**
 * @constant ROLES
 */
export const ROLES = {
  ADMIN: "admin",
  USER: "user",
};

/**
 * @constant FILE_STORAGE_PATH : To define path of uploaded files on firebase storage
 */
export const FILE_STORAGE_PATH = {
  user_profile_photo: "users/profileImage/",
};
