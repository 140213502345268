import React from "react";
import { Button } from "reactstrap";
import axios from "axios";
import { API_URL, PAYMENT_STATUS } from "../../shared/constants";
import { appConfig } from "../../config";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import {generateLicenceKey} from '../../shared/utils/generateLicenceKey'
const Payment = ({
  userId,
  productId,
  paymentAmount,
  userName,
  productName,
  togglePayment,
  buttonText,
  isRenew,
  licenceNo,
  oldExpiryDate,
  buttonStyle,
  onToggle,
  isMulti,
  setLoading
}) => {
  const handlePayment = async () => {
  
     const newLicenceNo = await generateLicenceKey(userName)
     const licenceNoToSend =  licenceNo ? licenceNo :newLicenceNo
     const date = new Date()
     const expiryDate = oldExpiryDate ? oldExpiryDate : new Date(date.setMonth(date.getMonth()+12)).getTime()
    const { data } = await axios.post(API_URL.paymentOrders, {
      userId,
      amount: paymentAmount,
    });
    const options = {
      key: appConfig.RAZOR_PAY_TEST_KEY,
      name: appConfig.PROJECT_ID,
      description: productName,
      order_id: data.id,
      amount :paymentAmount*100,
      handler: async (response) => {
        try {
          const paymentId = response.razorpay_payment_id;
          setLoading(true)
          const captureResponse = await axios.post(API_URL.paymentCapture, {
            userId,
            paymentId,
            isMulti,
            amount: paymentAmount,
            productId,
            licenceNo:licenceNoToSend,
            expiryDate,
            productName,
            isRenew,
          });
          if (
            captureResponse.data &&
            captureResponse.data.message === PAYMENT_STATUS.CAPTURE.SUCCESS
          ) {
            //ToDo: handle on payment success
            console.log("success");
            setLoading(false)
            togglePayment()
            onToggle()
          } else {
            //ToDo: handle on payment failure
            console.log("failure");
          }
        } catch (error) {
          console.log("razor pay error", error);
        }
      },
      theme: {
        color: "#686CFD",
      },
    };

    const rzpInstance = new window.Razorpay(options);
    rzpInstance.open();
  };

  return (
    <div>
      
      {!isRenew ? 
      <Button color="theme" className= "buy-btn" onClick={handlePayment}>{buttonText} </Button>
      :
      <Button  className= "renew-btn" onClick={handlePayment}>{buttonText} <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
      </Button>
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.loader.isLoading,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (payload) => dispatch(actions.setLoading(payload)),
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(Payment);
// export default Payment;
