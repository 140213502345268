import React from "react";

import AboutUs from "./components/aboutUs";
import Description from "./components/description";
import Features from "./components/features";
import AboutOurProducts from "./components/aboutOurProducts";
import Trust from "./components/trust";
import Testimonial from "./components/testimonial";
import ComingSoon from "./components/comingSoon";
import TopBrands from "./components/topBrands";

const Home = () => {
  return (
    <div>
      <Description />
      <AboutUs />
      <AboutOurProducts />
      <ComingSoon />
      <Features />
      <Trust />
      {/* <Testimonial /> */}
      {/* <TopBrands /> */}
    </div>
  );
};

export default Home;
