import React from "react";
import { withRouter } from "react-router-dom";

import { ROUTES } from "../../shared/constants";
import PageNotFound from "./component";

const PageNotFoundContainer = (props) => {
  const navigateToHomePage = () => {
    props.history.replace(ROUTES.home);
  };

  return <PageNotFound onClick={navigateToHomePage} />;
};

export default withRouter(PageNotFoundContainer);
