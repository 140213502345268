import React from "react";
import { Container, Col, Row } from "reactstrap";
import trust1 from "../../../../assests/images/home/trust-1.png";
import trust2 from "../../../../assests/images/home/trust-2.png";
import trust3 from "../../../../assests/images/home/trust-3.png";

const TrustContainer = () => {
  return (
    <section className="business-trust-wrapper text-center">
      <Container>
        <Row>
          <Col md={12}>
            <h4 className="custom-title">
              <span className="text-contain">
                <span className="text">
                  Why millions of Businesses trust us?
                </span>
                <span className="backText">Trust</span>
              </span>
            </h4>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md={4}>
            <div className="trust-wrapper">
              <img src={trust1} alt="trust" />
              <h3>Smart Solution</h3>
              <p>
              At Microsys all our solutions are automated. This has helped our clients save time and make themselves involve more into the business strategic tasks for business growth.
              </p>
            </div>
          </Col>
          <Col md={4}>
            <div className="trust-wrapper">
              <img src={trust2} alt="trust" />
              <h3>Uncompromised Support</h3>
              <p>
              With CA MAX you are constanly updated and Our team work really hard to provide instant support.
              </p>
            </div>
          </Col>
          <Col md={4}>
            <div className="trust-wrapper">
              <img src={trust3} alt="trust" />
              <h3>Pricing</h3>
              <p>
              We deliver our products at the most reasonable pricing that keeps our customers on board. Software updates are made with low maintenance costs making scaling easier.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TrustContainer;
