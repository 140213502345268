import React from "react";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import inputFields from "./formData";

const AccountDetails = ({ values, onChange,isEditProfile }) => {
  return (
    <Row>
      {Object.keys(inputFields).map((key) => (
        <Col md={inputFields[key].col}>
          {!(isEditProfile && (key === "password"||key === "confirmPassword")) &&<FormGroup key={key}>
            <Label>{inputFields[key].label}{inputFields[key].isRequired && <span className="required">*</span> }</Label>
            <Input
              value={values[key]}
              type={inputFields[key].type}
              onChange={onChange}
              id={inputFields[key].id}
            />
          </FormGroup>}
        </Col>
      ))}
    </Row>
  );
};

export default AccountDetails;
