import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Container,
  Row,
  Col,
} from "reactstrap";
import ArrowLeft from "../../assests/images/contact/arowleft.png";

const SignIn = ({
  email,
  password,
  onChange,
  onSignIn,
  onToggleAuthMode,
  togglePasswordModal,
}) => {
  return (
    <div className="register-wrapper">
      <Container>
        <Row>
          <Col md={6}>
            <div className="register-content">
              <h3>Sign-in today to enjoy unlimited benefits</h3>
              <h2>
                Login to <span>CA Max</span> by entering your registered Email Id and Password.
              </h2>
              <p>
               Let’s not lose your Progress. Login to access works and start right where you left of. New to CA Max? Please click the Signup option and enter your details to enjoy the services of CA MAX. Your information is securely handled and will not be shared with a third entity.
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div className="register-form login-form">
              <Card>
                <CardBody>
                  <div>
                    <center>
                      <h2>CA Max</h2>
                    </center>
                    <FormGroup>
                      <Label>Email</Label>
                      <Input
                        value={email}
                        type={"email"}
                        onChange={onChange}
                        name={"email"}
                        id={"email"}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Password</Label>
                      <Input
                        value={password}
                        type={"password"}
                        onChange={onChange}
                        name={"password"}
                        id={"password"}
                      />
                    </FormGroup>
                    <div className="text-center pt-4">
                      <Button
                        onClick={onSignIn}
                        color="theme"
                        className="register-btn"
                      >
                        Sign In <img src={ArrowLeft} className="ml-2" />
                      </Button>
                      <p>
                        <Button
                          onClick={togglePasswordModal}
                          className="forgot-btn"
                        >
                          Forgot password?
                        </Button>
                      </p>
                      <p className="signup-link">
                        Don't have an account?{" "}
                        <span onClick={onToggleAuthMode}>Sign Up</span>
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignIn;
