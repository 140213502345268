import React,{useState,useEffect} from "react";
import {
  Row,
  Input,
  Label,
  Modal,
  ModalBody,
  FormGroup,
  Col,
  Button,
  ModalHeader,
} from "reactstrap";
import Alert from "../../../../shared/utils/alert";
import { connect } from "react-redux";
import closeIcon from "../../../../assests/images/close.svg";
import Tick from "../../../../assests/images/tick.png";
import Payment from '../../../../components/payment'
import { withFirebase } from "../../../../firebase";
import { compose } from "redux";

const LicenceModal = ({ isOpen, onToggle, togglePayment ,user,firebase,}) => {
const [availablePlans, setAvailablePlans] =useState()
// const [coupon, setCoupon] = useState()
const [couponDetail, setCouponDetail] = useState()
// const [isCouponApplied, setCoupon] = useState()
// const [couponAmount, setCouponAmount] = useState(0)
useEffect(()=>{
  getProductDetail()
},[])
const getProductDetail = async()=>{
 const result = await firebase.getProducts()
 const data = result.map(el=>{
   return {value : ""}
 })
 setAvailablePlans(result)
 setCouponDetail(data)
}
const applyCoupon = async (index)=>{
  const newArray = couponDetail.slice()
const coupon = newArray[index] && newArray[index].value
  if(!coupon){
    Alert(400, "Please Enter Coupon!!");
    return;
  }
 const result =  await firebase.isValidCoupon(coupon)
 if(result){
   const couponPrice = result[0] && result[0].amount || 0
   let data =  newArray[index]
    data = {
     ...data ,
     price : couponPrice,
     isApplied : true,
   }
   newArray[index] = data
   setCouponDetail(newArray)
  // setCouponAmount(couponPrice)
  Alert(200, "Coupon applied !!");
 }else{
  Alert(400, "Coupon not Valid!!");
  return;
 }
  
}
const handleCouponChange = (e,index)=>{
  const newArray= couponDetail.slice()
  let data = newArray[index]
  const value = e.target.value
  data = {
    value
  }
  newArray[index] = data
  setCouponDetail(newArray)
  // setCoupon(e.target.value)
}

const handleChange = (e,index)=>{
  const newArray = [...availablePlans]
  let item = newArray[index]
  if(!e.target.checked){
    item = {
      ...item,
      selectedPlan:'single'
    }
  }else{
    item = {
      ...item,
      selectedPlan:'multi'
    }
  }
  newArray[index] =item
  setAvailablePlans(newArray)
}
const dataLength = availablePlans && availablePlans.length || 0
  return (
    <Modal
      isOpen={isOpen}
      toggle={onToggle}
      centered
      className="buy-license-modal"
    >
      <ModalHeader>
      <button onClick={onToggle} className="modal-close">
          <img src={closeIcon} alt="close" />
        </button>
      </ModalHeader>
      <ModalBody>
       
        <div className="buy-license-wrapper">
          <h2>
            Want to buy <span>New License?</span>
          </h2>
          <p className="license-text">
            Here are the Flexible Plans you can choose that works best for you.
          </p>

          <Row className="justify-content-center">
            {
              availablePlans && availablePlans.map((el,index) => {
                const amount = el.selectedPlan=='single'?el.singleUserAmount:el.multiUserAmount
                const isMulti = el.selectedPlan=='single'?false:true
                const couponPrice = couponDetail && couponDetail[index] && couponDetail[index].price || 0
                const isCouponApplied = couponDetail && couponDetail[index] && couponDetail[index].isApplied || false
                 
                return (
                  <>
                 
                  <Col sm={dataLength > 2 ? 4 : 6 } >
                    <div className="license-plan ">
                      <div className="license-header">
                        <p className="plans-name">
                          <span>{el.name}</span>
                        </p>
                        <h4>
                          <sup>₹</sup>
                          <span>{amount}/</span>user
                         </h4>
                        <label className="switch">
                          <input type="checkbox"  onChange = {(e)=>{
                            handleChange(e,index)
                          }}/>
                          <span className="slider round" />
                          <span className="text single ">Single User</span>
                          <span className="text multiple">Multiple User</span>
                        </label>
                      </div>

                      <ul>
                        <li>
                          <img src={Tick} />
                           Here are the Flexible plans you can choose.
                         </li>
                        <li>
                          <img src={Tick} />
                             Here are the Flexible plans you can choose.
                        </li>
                      </ul>
                      <div className="d-flex mb-3">
                     <Input placeholder = "enter coupon " value = {couponDetail && couponDetail[index] && couponDetail[index].value} onChange = {(e)=>{handleCouponChange(e,index)}}/>

                     <Button className="ml-3" onClick = {()=>{applyCoupon(index)}} disabled = {isCouponApplied}> {!isCouponApplied ? "Apply" :"Applied" }</Button>
                     </div>
                    </div>
                   
                    <Payment userId={user.uid} productId={el.id} productName = {el.productName}paymentAmount={amount - couponPrice} userName= {user.username} onToggle = {onToggle} isMulti = {isMulti} togglePayment = {togglePayment} buttonText = "Buy" />
                  </Col>
                 
                  </>
                )
              })
            }
          </Row>
        </div>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});
export default compose(
  withFirebase,
  connect(mapStateToProps)
)(LicenceModal)
// export default compose(withFirebase,connect(mapStateToProps))(LicenceModal);

// export default LicenceModal;
