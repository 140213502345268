import React from "react";
import { Button } from "reactstrap";
import notification from "../../../../assests/images/dashboard/notification.png";
import logout from "../../../../assests/images/dashboard/logout.png";
import user from "../../../../assests/images/dashboard/user.png";

const Header = ({
  title = "Dashboard",
  searchText = "",
  userDetail,
  onChange = () => {},
  onLogout = () => {},
}) => (
  <div className="d-flex dashboard-top-content justify-content-between">
    <div className="d-flex align-items-center justify-content-between w-100 mb-md-0 mb-3">
      <h2>{title}</h2>
      <div className="account-wrapper d-md-none">
        {/* <Button color="icon">
          <img src={notification} alt="notify" />
        </Button> */}
        <ul className="user-account-wrapper list-unstyled">
          <li>
            <img
              src={userDetail.photoUrl || user}
              className="mr-2 user"
              alt="user"
            />
            <span>{userDetail.username || "Anonymus"} </span>
          </li>
          <li>
            <Button color="icon" onClick={onLogout}>
              <img src={logout} className="mr-2" alt="logout" />
              <span>Logout</span>
            </Button>
          </li>
        </ul>
      </div>
    </div>

    <div className="top-content">
      {title == "Dashboard" && (
        <input
          type="text"
          placeholder="Enter product name or License key"
          value={searchText}
          onChange={onChange}
        />
      )}
      <div className="account-wrapper d-md-block d-none">
        {/* <Button color="icon">
          <img src={notification} alt="notify" />
        </Button> */}
        <ul className="user-account-wrapper list-unstyled">
          <li>
            <img
              src={userDetail.photoUrl || user}
              className="mr-2 user"
              alt="user"
            />
            <span>{userDetail.username || "Anonymus"} </span>
          </li>
          <li>
            <Button color="icon" onClick={onLogout}>
              <img src={logout} className="mr-2" alt="logout" />
              <span>Logout</span>
            </Button>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default Header;
