import React, { useState } from "react";
import { Container, Col, Row } from "reactstrap";
import EmployeeForm from "./components/form/employeeForm";
import Alert from "../../shared/utils/alert";
import RecentJobs from "./components/recentJobs";
import { withFirebase } from "../../firebase";
import { compose } from "redux";
import CarrierBannerImg from "../../assests/images/commonImages/carrierbannerimg.png";
import { validateEmail, validatePhone } from "../../shared/utils/validations";
const formDetail = {
  name: "",
  email: "",
  phone: "",
  jobTitle: "",
  experience: "",
  ctc: "",
  about: "",
};
const JobSeekers = (props) => {
  const { firebase } = props;
  const [fields, setFields] = useState(formDetail);
  const handlePost = (e) => {
    e.preventDefault();
    const { name, email, phone, jobTitle, experience, ctc, about } = fields;
    if (!name || !email || !phone || !jobTitle || !experience) {
      Alert(400, "Please enter required fields!!");
      return;
    }
    if (!validateEmail(email)) {
      Alert(400, "Please enter valid email address!!");
      return;
    }
    if (!validatePhone(phone)) {
      Alert(400, "Please enter valid Phone number!!");
      return;
    }
    let data = fields;
    data = {
      ...data,
      status: "pending",
    };
    firebase.addEmployeePost(data, (res) => {
      if (res) {
        Alert(200, "Your application for job has been registered, It will be online shortly after Screening");
      }
    });
    setFields(formDetail);
  };
  const handleChange = (event) => {
    const key = event.target.id;
    const value = event.target.value;
    setFields((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <div className="employee-wrapper">
      <Container>
        <Row className="align-items-center">
          <Col lg={7}>
            <EmployeeForm
              onPost={handlePost}
              onChange={handleChange}
              values={fields}
            />
          </Col>
          <Col lg={5} className="d-lg-block d-none">
            <img src={CarrierBannerImg} className="img-fluid" />
          </Col>
        </Row>
        <Row>
          <Col lg={7}>
            <RecentJobs />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default compose(withFirebase)(JobSeekers);
