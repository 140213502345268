import React from "react";
import formData from "../formData";
import {
  Container,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  InputGroup,
} from "reactstrap";
import Arrowleft from "../../../../assests/images/contact/arowleft.png";

function EmployerForm(props) {
  const { onPost, onChange, values } = props;

  return (
    <>
      <div className="employee-details-wrapper">
        {/* <h3>Lorem Ipsum is simply Lorem</h3> */}
        <h2>
          {/* Lets get in <span>Touch </span> */}
          Submit your candidate requirements, we will match you up with top talents.
        </h2>
      </div>
      <div className="employee-form-wrapper">
        <Card>
          <CardBody>
            {Object.keys(formData).map((section) => {
              const heading =
                section === "section1" ? (
                  <h2>Employer Form </h2>
                ) : section === "section2" ? (
                  <h2 className="mt-4">Registration</h2>
                ) : (
                  ""
                );
              const colvalue = section === "section3" ? 12 : 4;
              return (
                <Row>
                  <Col md={12}>{heading}</Col>
                  {Object.keys(formData[section]).map((key) => {
                    const field = formData[section][key];
                    return (
                      <Col md={colvalue}>
                        <FormGroup>
                          <label>
                            {field.label}
                            {field.required && (
                              <span className="required">*</span>
                            )}
                          </label>
                          <InputGroup
                            className={`${field.icon ? "icon-group" : ""}`}
                          >
                            <Input
                              value={values[key]}
                              type={field.type}
                              onChange={onChange}
                              id={field.id}
                            />
                            <img src={field.icon} />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
            <div className="text-center">
              <Button
                color="theme"
                onClick={onPost}
                className="employee-submit-button"
              >
                Post
                <img src={Arrowleft} className="ml-1" />
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default EmployerForm;
