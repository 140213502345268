import {
  validateEmail,
  validatePassword,validatePhone,
} from "../../../../shared/utils/validations";

export const validateFormData = (values) => {
  const {
    username,
    companyName,
    address,
    city,
    state,
    country,
    gstInNumber,
    email,
    password,
    confirmPassword,
    phone,
  } = values;

  if (
    !username ||
    !companyName ||
    !address ||
    !city ||
    !state ||
    !country ||
    !gstInNumber ||
    !email ||
    // !password ||
    // !confirmPassword||
    !phone
  ) {
    return "All fields are required!!";
  }

  //ToDo: update gstInNumber validation
  // if (gstInNumber) {
  //   return "";
  // }

  if (!validateEmail(email)) {
    return "Please enter valid email address!!";
  }

  // if (!validatePassword(password)) {
  //   return "Password should be of atleat 6 characters!!";
  // }
  if(!validatePhone(phone)){
    return "Please enter valid phone Number!!";
  }

  // if (password.toLowerCase() !== confirmPassword.toLowerCase()) {
  //   return "Password not matched!!";
  // }

  return "";
};
