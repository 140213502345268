import React from "react";
import { Container } from "reactstrap";

import DashboardContent from "./components/content";
import Header from "./components/header";
import Profile from "./components/profile";
import Settings from "./components/settings";
import Sidebar from "./components/sidebar";
import Tabs, { TAB_KEY_LIST } from "./components/sidebar/data";

const Dashboard = ({ tab, onSelectTab, onSearch,licenceData,togglePayment,paymentDone }) => {
  const renderView = () => {
    switch (tab) {
      case TAB_KEY_LIST.DASBOARD:
        return <DashboardContent licenceData = {licenceData}
        togglePayment={togglePayment}
        paymentDone = {paymentDone}/>;
      case TAB_KEY_LIST.EDIT_PROFILE:
        return <Profile />;
      // case TAB_KEY_LIST.SETTINGS:
      //   return <Settings />;

      default:
        return null;
    }
  };

  return (
    <div className="dashboard-main-wrapper">
      <Container>
        <Header onSearch={onSearch} title={Tabs[tab].title} />
        <div className="d-flex flex-lg-row flex-column">
          <div className="dashboard-sidebar-wrapper">
            <Sidebar selected={tab} onSelect={onSelectTab} />
          </div>
          <div className="dashboard-content-wrapper">{renderView()}</div>
        </div>
      </Container>
    </div>
  );
};

export default Dashboard;
