import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { sendMail } from "../../shared/utils/email";
import { withFirebase } from "../../firebase";
import SignUp from "./component";
import { validateFormData } from "./utils";
import { ROUTES } from "../../shared/constants";
import Alert from "../../shared/utils/alert";
import * as actions from "../../redux/actions";
import { API_URL } from "../../shared/constants"
import VerifyEmail from './verifyEmail'
import { VerifyEmailTemplate } from '../../shared/utils/emailTemplates/verifyEmailTemplate'
const userDetail = {
  username: "",
  companyName: "",
  address: "",
  city: "",
  state: "",
  country: "",
  gstInNumber: "",
  email: "",
  password: "",
  confirmPassword: "",
  phone: ""
};
let userId, link
const SignUpContainer = ({
  history,
  firebase,
  setUser,
  setLoading,
  onToggleAuthMode,
}) => {
  const [fields, setFields] = useState(userDetail);
  const [email, setEmail] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [linkToSend, setLinkToSend] = useState()
  const handleChange = (values) => {
    setFields(values);
  };

  const handleRegister = () => {

    const payload = {
      username: fields.username,
      companyName: fields.companyName || '',
      address: fields.address,
      city: fields.city,
      state: fields.state,
      country: fields.state,
      gstInNumber: fields.gstInNumber || '',
      email: fields.email,
      password: fields.password,
      phone: fields.phone
    };
    setEmail(fields.email)
    firebase
      .signUp(payload)
      .then((response) => {
        if (response.status === 200) {
          Alert(200, "Registered sucessfully!!");
          setLoading(false)
          const userId = response.data.uid
          const link = API_URL.confirmUser + "/" + userId;
          // console.log("linklinklinklink=",link)
          const userEmail = payload.email
          sendEmailToUser({ link, userEmail })
          setLinkToSend(link)
          setIsOpen(true)
        } else {
          setLoading(false);
          Alert(
            400,
            (response.data && response.data.message) || response.message
          );
        }
      })
      .catch(() => {
        setLoading(false);
        Alert(
          400,
          "Somthing went wrong. Unable to register at this time. Please try again!!"
        );
      });
  };

  const sendEmailToUser = ({ link, userEmail }) => {
    const content = VerifyEmailTemplate({link})
    const from = "mmahajaninnow8@gmail.com"
    const to = "devinnow8@gmail.com"
    const data = {
      from: from,
      to: userEmail,
      subject: "Email verification",
      content,
    };
    sendMail(data)
      .then(() => {
        Alert(200, "Email sent successfully.");
      })
      .catch(() => {
        Alert(400, "Unable to send email at this time.");
      });
  }

  const resendEmail = () => {
    sendEmailToUser({linkToSend,email})

  }
  const toggleVerificationModal = () => {
    setIsOpen(!isOpen)
  }

  const handleSignUp = () => {
    const validationResponse = validateFormData(fields);
    if (!!validationResponse) {
      Alert(400, validationResponse);
      return;
    }
    setFields(userDetail)
    setLoading(true);
    handleRegister();
  };

  return (
    <>
      <SignUp
        values={fields}
        onChange={handleChange}
        onSignUp={handleSignUp}
        onToggleAuthMode={onToggleAuthMode}
      />
      <VerifyEmail
        email={email}
        isOpen={isOpen}
        toggle = {toggleVerificationModal}
        resendEmail={resendEmail} />
    </>
  );
};



const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (payload) => dispatch(actions.setUser(payload)),
    setLoading: (payload) => dispatch(actions.setLoading(payload)),
  };
};

export default compose(
  withFirebase,
  withRouter,
  connect(null, mapDispatchToProps)
)(SignUpContainer);
