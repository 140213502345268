import React from "react";
import { withRouter } from "react-router-dom";
import {ROUTES} from '../../../../shared/constants'

const Company = (props) =>{ 
  const {history} = props
  const redirectTo = (navigateTo)=>{
    // console.log("propsprops=",props)
    history.push(navigateTo);
    
  }
 return  (
  <div className="footer-detail">
    <h3>Company</h3>
    <p onClick = {()=>{redirectTo("/")}}>About Us</p>
    <p onClick = {()=>{redirectTo(ROUTES.contactUs)}}>Contact Us</p>
    {/* <p onClick = {()=>{resirectTo("/downloads")}}>Downloads</p> */}
    <p onClick = {()=>{redirectTo(ROUTES.carrier)}}>Carrier</p>
    {/* <p onClick = {()=>{resirectTo("/job-seekers")}}>JobSeeker</p> */}
    
  </div>
)};
export default withRouter(Company)
// export default Company;
