import React from "react";
import Description from "./components/description";
import ProductList from "./components/list";
import Products from "./components/products";

const OurProducts = () => {
  return (
    <div>
      <Description />
      <Products />
      <ProductList />
    </div>
  );
};

export default OurProducts;
