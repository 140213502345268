import React from "react";
import { withRouter } from "react-router-dom";

import ContactUs from "./component";

const SalesPartners = () => {
  return <ContactUs />;
};

export default withRouter(SalesPartners);
