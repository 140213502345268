export const SET_IS_LOADING = "SET_IS_LOADING";

/**
 * @method setLoading : To set state of loader view
 *
 * @param {boolean} isLoading
 *
 */
export const setLoading = (isLoading) => {
  return {
    payload: { isLoading },
    type: SET_IS_LOADING,
  };
};
