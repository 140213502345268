import React from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import AboutOurProducts from "../../../../assests/images/home/about-our-products.png";
import about1 from "../../../../assests/images/home/about-1.png";
import about2 from "../../../../assests/images/home/about-2.png";
import about3 from "../../../../assests/images/home/about-3.png";
import { About } from "./util";
const AboutOurProductsContainer = () => {
  return (
    <section className="about-our-products-wrapper">
      <Container>
        <Row className="align-items-center">
          <Col md={12} className="text-center">
            <h4 className="custom-title">
              <span className="text-contain">
                <span className="text">About our Products</span>
                <span className="backText">Products</span>
              </span>
            </h4>
          </Col>
        </Row>

        <Row className="mt-xl-5 pt-5">
          <Col md={4}>
            <div className="text-center">
              <img src={about1} alt="img1" />
              <h3>CA Max</h3>
            </div>

            <p className="text-left">
              Complete Accounting System for all Businesses: Manufacturers,
              Traders, Institutes, Professionals Etc..
              {/* <ul>
<li>Multi Company System (Can Create any number of Companies)</li>
<li>Separate Location for Personal Accounts</li>
<li>Latest and Time-to-Time Amended GST System, Very Easy and User Friendly Return Making Modules.</li>
<li>E-Way and E-Invoicing Automatic thru API and JSON (Both Methods).</li>
<li> Invoicing & Inventory Systems for All Manufacturers, Traders, Institutes, Professionals. Customized Billing.</li>
 <li> Pharma Trade Billing & Inventory.</li>
 <li> Barcode Based Retail Counter POS Billing.</li>
 <li> Customer Based Invoice Format Designing.</li>
 <li> Option of Multiple (Any Number of) Invoice Designs and page layouts.</li>
 <li> Design your Own Formats.</li>
 <li> Company/Brand Logos on Invoice Formats.  </li>
</ul> */}
              <ul>
                {About.CAMAX.map((el, idx) => {
                  return <li key={idx}> {el} </li>;
                })}
              </ul>
            </p>
          </Col>
          <Col md={4}>
            <div className="text-center">
              <img src={about2} alt="img2" />
              <h3>Pay Max</h3>
            </div>

            <p className="text-left">
              <ul>
              {About.PAYMAX.map((el, idx) => {
                  return <li key={idx}> {el} </li>;
                })}
                {/* <li> Payroll System for small to large companies</li>
                <li>User-defined Salary Structures</li>
                <li>All Statutory Reports</li>
                <li>ESI/EPF E-Filing</li>
                <li>Salary Slips, Wages Slips</li>
                <li>Salary Registers, Wages Register</li>
                <li>Location Wise,</li>
                <li>Designation Wise,</li>
                <li>Department Wise</li>
                <li>Contractor Based</li> */}
              </ul>
            </p>
          </Col>
          <Col md={4}>
            <div className="text-center">
              <img src={about3} alt="img3" />
              <h3>CD AD Max</h3>
            </div>
            <p className="text-left">
              <ul>
                <li>Advertising Agency Management System </li>
                <li>
                  Includes: Release Orders, Tariff Cards, Single/Combined
                  Editions,
                </li>
                <li>
                  Client Billing, Publication Billing, Cr.Notes, Daily
                  Scheduling,{" "}
                </li>
                <li>Financial Accounting, Insertion-Wise Profits, </li>
                <li>Publication/Client Payment Outstanding System.</li>
              </ul>
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default withRouter(AboutOurProductsContainer);
