import React from "react";
import { withRouter } from "react-router-dom";

import Carrier from "./component";

const CarrierContainer = () => {
  return <Carrier />;
};

export default withRouter(CarrierContainer);
