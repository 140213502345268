import React from 'react';

function PrivacyPoliciesContainer(props) {
  return (
    <div className = 'mr-5 ml-5'>
      <br/> <br/>
      <br/> <br/>
      <br/> <br/>
     <h1> PRIVACY NOTICE </h1>

<br/> <br/>Last updated March 06, 2021


<br/> <br/>
Thank you for choosing to be part of our community at Microsys & Equipments ("Company", "we", "us", "our"). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at microsysindia63@gmail.com.
<br/> <br/>
When you visit our website http://www.camaxonline.com (the "Website"), use our mobile application, as the case may be (the "App") and more generally, use any of our services (the "Services", which include the Website and App), we appreciate that you are trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.
<br/> <br/>
This privacy notice applies to all information collected through our Services (which, as described above, includes our Website and App), as well as, any related services, sales, marketing or events.
<br/> <br/>
Please read this privacy notice carefully as it will help you understand what we do with the information that we collect.
<br/> <br/>
TABLE OF CONTENTS
<br/> <br/>
1. WHAT INFORMATION DO WE COLLECT? <br/>
2. WILL YOUR INFORMATION BE SHARED WITH ANYONE? <br/>
3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? <br/>
4. HOW DO WE HANDLE YOUR SOCIAL LOGINS? <br/>
5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY? <br/>
6. HOW LONG DO WE KEEP YOUR INFORMATION? <br/>
7. DO WE COLLECT INFORMATION FROM MINORS? <br/>
8. WHAT ARE YOUR PRIVACY RIGHTS? <br/>
9. CONTROLS FOR DO-NOT-TRACK FEATURES <br/>
10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS? <br/>
11. DO WE MAKE UPDATES TO THIS NOTICE? <br/>
12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? <br/>
13. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU? <br/>
<br/>
1. WHAT INFORMATION DO WE COLLECT?
<br/>
<br/> 
2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
<br/>
<br/> 
In Short:  We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.
<br/> 
<br/>

We may process or share your data that we hold based on the following legal basis:
<br/>

Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.
<br/>

Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.
<br/>

Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.
<br/>

Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
<br/>
Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
<br/>

More specifically, we may need to process your data or share your personal information in the following situations:
<br/>
Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
<br/>

Affiliates. We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.
Business Partners. We may share your information with our business partners to offer you certain products, services or promotions.
<br/>

Other Users. When you share personal information or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. If you interact with other users of our Services and register for our Services through a social network (such as Facebook), your contacts on the social network will see your name, profile photo, and descriptions of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.
<br/>
<br/> 

3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
<br/>

In Short:  We may use cookies and other tracking technologies to collect and store your information.
<br/>


We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
<br/> 

<br/> 
4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?     
<br/> 

In Short:  If you choose to register or log in to our services using a social media account, we may have access to certain information about you.
<br/> 

Our Services offers you the ability to register and login using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, profile picture as well as other information you choose to make public on such social media platform.
<br/> 

We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.
<br/> 
<br/> 
5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?     
<br/> 

In Short:  We may transfer, store, and process your information in countries other than your own.
<br/> 

Our servers are located in. If you are accessing our Services from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "WILL YOUR INFORMATION BE SHARED WITH ANYONE?" above), in and other countries.
<br/> 

If you are a resident in the European Economic Area, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. We will however take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.
<br/> 
<br/> 
6. HOW LONG DO WE KEEP YOUR INFORMATION?
<br/> 

In Short:  We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
<br/> 
<br/> 

When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
<br/> 
<br/> 
7. DO WE COLLECT INFORMATION FROM MINORS?
<br/> 

In Short:  We do not knowingly collect data from or market to children under 18 years of age.
<br/> 

We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. 
<br/> 
<br/> 
8. WHAT ARE YOUR PRIVACY RIGHTS?
<br/> 

In Short:  You may review, change, or terminate your account at any time.
<br/> 
 
If you are a resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
<br/> 

If you are a resident in Switzerland, the contact details for the data protection authorities are available here: https://www.edoeb.admin.ch/edoeb/en/home.html.
<br/> 
<br/> 
9. CONTROLS FOR DO-NOT-TRACK FEATURES
<br/> 

Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice. 
<br/> <br/> 

10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
<br/> 

In Short:  Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
<br/> 
<br/> 

California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
<br/> 
<br/> 

If you are under 18 years of age, reside in California, and have a registered account with a Service, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).
<br/> 
<br/> 
11. DO WE MAKE UPDATES TO THIS NOTICE?     
<br/> 

In Short:  Yes, we will update this notice as necessary to stay compliant with relevant laws.
<br/> 

We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
<br/> 
<br/> 
12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?     
<br/> 

If you have questions or comments about this notice, you may email us at microsysindia63@gmail.com or by post to:
<br/> 

Microsys & Equipments
DSS 147 P,
Sector 11,
Panchkula, Haryana 134109
India
<br/> 

<br/> 

    </div>
  );
}

export default PrivacyPoliciesContainer;