import app from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import { sendMail } from "../shared/utils/email";
import moment from 'moment'

import FirebaseContext, { withFirebase } from "./context";
import { appConfig } from "../config";
import { SCHEMA, ROLES } from "../shared/constants";

const config = {
  apiKey: appConfig.API_KEY,
  authDomain: appConfig.AUTH_DOMAIN,
  databaseURL: appConfig.DATABASE_URL,
  projectId: appConfig.PROJECT_ID,
  messagingSenderId: appConfig.MESSAGING_SENDER_ID,
  appId: appConfig.APP_ID,
  storageBucket: appConfig.STORAGE_BUCKET,
};

class Firebase {
  constructor() {
    if (!app.apps.length) {
      app.initializeApp(config);
    }

    this.auth = app.auth();
    this.storage = app.storage();
    this.database = app.database();
    this.lastVisible = null
  }
  /******************************************************/
  /****************** Authorization *********************/
  /******************************************************/

  /**
   *  @method getAuth : To get authorization of user
   */
  getAuth = () => this.auth;

  /**
   * @method signUp : To signup user using email and password
   *
   * @param {object} payload
   *
   * @param {string} username
   * @param {string} companyName
   * @param {string} address
   * @param {string} city
   * @param {string} state
   * @param {string} country
   * @param {string} gstInNumber
   * @param {string} email
   * @param {string} password
   */
  signUp = (payload) => {
    return new Promise((resolve) => {
      const { username, email, password, ...others } = payload;
      this.getAuth()
        .createUserWithEmailAndPassword(email, password)
        .then((response) => {
          if (response.code === "auth/email-already-in-use") {
            resolve({ status: 400, message: response.message });
          } else {
            const data = {
              ...others,
              email,
              username: username.toLowerCase(),
              role: ROLES.USER,
              isConfirmed: false,
              uid : response.user.uid
            };
            this.createUser(response.user.uid, data);
            resolve({ status: 200, message: "Signup success.", data });
          }
        })
        .catch((error) => {
          resolve({ status: 400, message: "Unable to signup", data: error });
        });
    });
  };

  /**
   * @method signInWithEmailPassword : To signin user using email and password
   *
   * @param {string} email
   * @param {string} password
   */
  signInWithEmailPassword = (email, password) => {
    return new Promise((resolve) => {
      this.getAuth()
        .signInWithEmailAndPassword(email, password)
        .then((response) => {
          if (response) {
            this.getUser("email", email)
              .then((user) => {
                if (user) {
                  resolve({
                    status: 200,
                    message: "SignIn success.",
                    data: user,
                  });
                } else {
                  resolve({ status: 400, message: "User not found." });
                }
              })
              .catch((error) => {
                resolve({
                  status: 400,
                  message: "Something went wrong , please try again!!",
                  data: error,
                });
              });
          } else {
            resolve({ status: 400, message: "Unable to signIn" });
          }
        })
        .catch((error) => {
          resolve({ status: 400, message: "Unable to signIn", data: error });
        });
    });
  };

  /**
   * @method signOut :  To signout user
   */
  signOut = () => {
    this.getAuth().signOut();
  };

  /******************************************************/
  /****************** Database *********************/
  /******************************************************/

  /**** USERS ****/

  /**
   * @method getUser : To fetch user data
   *
   * @param {string} key : email of field
   * @param {string|number} value : value of given key
   *
   * @returns Promise that resolves or rejects query
   */
  getUser = (key, value) => {
    return new Promise((resolve, reject) => {
      try {
        this.database
          .ref(SCHEMA.USERS)
          .orderByChild(key)
          .equalTo(value)
          .once("value", (snapshot) => {
            resolve(Object.values(snapshot.val() || {}));
          });
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * @method getAllUsers : To fetch user data
   *
   * @param {string} key : email of field
   * @param {string|number} value : value of given key
   *
   * @returns Promise that resolves or rejects query
   */
  getAllUsers = () => {
    return new Promise((resolve, reject) => {
      try {
        this.database.ref(SCHEMA.USERS).once("value", (snapshot) => {
          resolve(Object.values(snapshot.val() || {}));
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * @method addCAMAXPost : To add post by CAMAX
   * @param {object} data : values to be added
   *
   */
  addCAMAXPost = async (data, callback) => {
    await this.database.ref(SCHEMA.POSTBYCAMAX + "/").push(data)
    callback(true)
  };

  /**
   * @method addProducts : get user's licences
   * @param {object} data : data in table
   *
   */
  addProducts = (data) => {
    //  console.log("datadatadatadata=",data)
    this.database.ref(SCHEMA.PRODUCTS + "/").push(data)
  }

  /**
   * @method getProducts : get user's licences
   * 
   *
   */
  getProducts = () => {
    //  console.log("datadatadatadata=",data)
    return new Promise(async (resolve) => {
      const result = await this.database.ref(SCHEMA.PRODUCTS).once("value")
      if (result) {
        let data = []
        const values = result.val()
        const keys = values && Object.keys(values)
        keys &&   keys.forEach(key => {
          let res = values[key]
          res = {
            ...res,
            id: key
          }
          data.push(res)
        })
        resolve(data)
      }
      else {
        resolve(null)
      }
    })
  }

  /**
   * @method getUsersLicences : get user's licences
   * @param {string} uid : id of user
   *
   */

  getUsersLicences = async (id) => {
    return new Promise(async (resolve) => {
      const result = await this.database.ref(SCHEMA.USERS + "/" + id + "/licence").once("value")
      const values = result && result.val();
      const data = values && Object.values(values) || null
      resolve(data)
    })
  };

 /**
 * @method isValidCoupon : To check coupon is valid or not
 * @param {object} data : coupon name
 *
 */

 isValidCoupon = async (coupon)=>{
   return new Promise ( async (resolve, reject)=>{
     const result = await this.database.ref(SCHEMA.COUPONS).orderByChild('couponName').equalTo(coupon).once("value")
     if(result){
      const values = result && result.val();
      const data = values && Object.values(values) || null
      resolve(data)
     }else {
      resolve([])
     }
   })
 }



  /**
 * @method updateCAMAXPost : To delete post by CAMAX
 * @param {object} id : values to be added
 *
 */
  updateCAMAXPost = async (data, callback) => {
    const id = data.id
    const dataToUpdate = data.data
    await this.database.ref(SCHEMA.POSTBYCAMAX + "/" + id).update(dataToUpdate)
    callback(true)
  };

  JobByEmployee

  /**
  * @method deleteEmployeePost : To delete post by Employee
  * @param {object} id : values to be deleted
  *
  */
  deleteEmployeePost = async (id, callback) => {
    await this.database.ref(SCHEMA.POSTBYEMPLOYEE + "/" + id).remove()
    callback(true)
  };


  /**
  * @method deleteEmployeePost : To delete post by Employer
  * @param {object} id : values to be deleted
  *
  */
  deleteEmployerPost = async (id, callback) => {
    await this.database.ref(SCHEMA.POSTBYEMPLOYER + "/" + id).remove()
    callback(true)
  };


  /**
   * @method deleteCAMAXPost : To delete post by CAMAX
   * @param {object} id : values to be added
   *
   */
  deleteCAMAXPost = async (id, callback) => {
    await this.database.ref(SCHEMA.POSTBYCAMAX + "/" + id).remove()
    callback(true)
  };

  /**
   * @method getAllPostByCAMAX : To get all  post added by CAMAX
   *
   */
  getAllPostByCAMAX = async (data, callback) => {
    return new Promise(async (resolve) => {
      const result = await this.database.ref(SCHEMA.POSTBYCAMAX + "/").once("value")
      if (result) {
        let data = []
        const values = result.val()
        const keys = values && Object.keys(values)
       keys && keys.forEach(key => {
          let res = values[key]
          res = {
            ...res,
            id: key
          }
          data.push(res)
        })
        resolve(data)
      } else {
        resolve(null)
      }

    })
  };

  /**
   * @method getAllApplicants : To add post by CAMAX
   * 
   *
   */
  getAllApplicants = async () => {
    return new Promise(async (resolve) => {
      const result = await this.database.ref(SCHEMA.APPLICANTS + "/").once("value")
      if (result) {
        let data = []
        const values = result.val()
        const keys = values && Object.keys(values)
        keys.forEach(key => {
          let res = values[key]
          res = {
            ...res,
            id: key
          }
          data.push(res)
        })
        resolve(data)
      } else {
        resolve(null)
      }

    })

  };


  /**
   * @method addEmployeePost : To add post by employee
   * @param {object} data : values to be added
   *
   */
  addEmployeePost = async (data, callback) => {
    await this.database.ref(SCHEMA.POSTBYEMPLOYEE + "/").push(data)
    callback(true)
  };
  /**
 * @method getAllPostByEmployee : To get all post by employee
 * 
 *
 */
  getAllPostByEmployee = async (offset, limit) => {
    if ((offset >= 0) && limit) {
      const result = await this.getEmployeePostWithLimit(offset, limit)
      return result
    } else {
      const result = await this.getAllEmployeePost()
      return result
    }
  };

  getEmployeePostWithLimit = (offset, limit) => {

    return new Promise(async (resolve) => {
    
        const result = await this.database.ref(SCHEMA.POSTBYEMPLOYEE).orderByChild('status').equalTo('accepted').once("value")
        if (result) {
          let data = []
          const values = result.val()
          
          const keys = values && Object.keys(values)
         keys && keys.forEach(key => {
            let res = values[key]
            res = {
              ...res,
              id: key
            }
            data.push(res)
          })
          const newData = data.slice()
          const response = {
            totalData : data && data.length,
            data : newData.splice(offset,limit)
          }
          resolve(response)
        } else {
          resolve(null)
        }
    })

  }

 

  getAllEmployeePost = () => {
    return new Promise(async (resolve) => {
      const result = await this.database.ref(SCHEMA.POSTBYEMPLOYEE).orderByChild('status').equalTo('accepted').once("value")
      if (result) {
        let data = []
        const values = result.val()
        const keys = values && Object.keys(values)
        keys.forEach(key => {
          let res = values[key]
          res = {
            ...res,
            id: key
          }
          data.push(res)
        })
        resolve(data)
      } else {
        resolve(null)
      }
    })
  }


  /**
     * @method addSubscribers : To subsciber user
     * 
     *
     */
  addSubscribers = async (data, callback) => {
    await this.database.ref(SCHEMA.SUBSCIBERS + "/").push(data)
    callback(true)
  };


  /**
   * @method addEmployerPost : To add post by employer
   * @param {object} data : values to be added
   *
   */
  addEmployerPost = async (data, callback) => {
    await this.database.ref(SCHEMA.POSTBYEMPLOYER + "/").push(data)
    callback(true)
  };


  /**
  * @method getAllPostByEmployer: To get all post by employer
  * 
  *
  */
  getAllPostByEmployer = async () => {
    // const res = await this.database.ref(SCHEMA.POSTBYEMPLOYER).orderByChild('status').equalTo('accepted').once("value")
    // console.log("resresres=",res.val())

    return new Promise(async (resolve) => {
      const result = await this.database.ref(SCHEMA.POSTBYEMPLOYER).orderByChild('status').equalTo('accepted').once("value")
      if (result) {
        let data = []
        const values = result.val()
        const keys = values && Object.keys(values) || []
        keys && keys.forEach(key => {
          let res = values[key]
          res = {
            ...res,
            id: key
          }
          data.push(res)
        })
        resolve(data)
      } else {
        resolve(null)
      }
    })
  };


  getEmployerPost = async (offset, limit )=>{
    if ((offset >= 0) && limit) {
      const result = await this.getEmployerPostWithLimit(offset, limit)
      return result
    } else {
      const result = await this.getAllPostByEmployer()
      return result
    }
  }

  getEmployerPostWithLimit = (offset,limit)=>{
    return new Promise(async (resolve) => {
    
      const result = await this.database.ref(SCHEMA.POSTBYEMPLOYER).orderByChild('status').equalTo('accepted').once("value")
      if (result) {
        let data = []
        const values = result.val()
        
        const keys = values && Object.keys(values)
       keys &&  keys.forEach(key => {
          let res = values[key]
          res = {
            ...res,
            id: key
          }
          data.push(res)
        })
        const newData = data.slice()
        const response = {
          totalData : data && data.length,
          data : newData.splice(offset,limit)
        }
        resolve(response)
      } else {
        resolve(null)
      }
  })
  }


  /**
* @method updateStatusOfEmployerPost : To update status of employer post 
* @param {string} id : id of post 
*
*/
  updateStatusOfEmployerPost = async (id, status, callback) => {
    await this.database.ref(SCHEMA.POSTBYEMPLOYER + "/" + id).update({ status: status })
    callback(true)
  }


  /**
* @method updateStatusOfEmployeePost : To update status of employee post 
* @param {string} id : id of post 
*
*/
  updateStatusOfEmployeePost = async (id, status, callback) => {
    await this.database.ref(SCHEMA.POSTBYEMPLOYEE + "/" + id).update({ status: status })
    callback(true)
  }


  /**
   * @method updateUser : Update an existing user
   *
   * @param {string} uid : user id
   * @param {object} data : values to be updated
   */
  updateUser = (uid, data,) => {
    this.database.ref(SCHEMA.USERS + "/" + uid).update(data);
    // var user = this.getAuth().currentUser;
    // user.updatePassword(newPassword).then(function () {
    //   console.log("success")
    //   // Update successful.
    // }).catch(function (error) {
    //   console.log("errorerror=", error)
    //   // An error happened.
    // });
  };

  /**
     * @method forgotPassword : Update an existing user
     *
     * @param {string} uid : user id
     * @param {object} data : values to be updated
     */
  forgotPassword = (email) => {
    var auth = this.getAuth();
    var emailAddress = email;

    auth.sendPasswordResetEmail(emailAddress).then(function () {
      console.log("Email sent",)
    }).catch(function (error) {
      console.log("errorerror=", error)
    });

  };



  /**
   * @method createUser : Create a new user
   *
   * @param {string} uid : user id
   * @param {object} data : user's detail
   */
  createUser = async (uid, data) => {
    await this.database.ref(SCHEMA.USERS + "/" + uid).set({ ...data });
  };

  /**
   * @method search : To search users for user details/licences/expiry date etc.
   *
   * @param {string} key
   * @param {string/number} value
   *
   * @returns Promise that resolves or rejects search query
   */
  search = (key, value) => {
    return new Promise(async (resolve, reject) => {
      try {
        const users = await this.database.ref(SCHEMA.USERS).once("value");
        const result = Object.values(users.val() || {}).filter((user) =>
          user[key].includes(value)
        );
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * @method updateUserLicences : Update an existing licence / Create new licence
   * 1. For new licence entry id , expiryDate & productId fields of data are required.
   * 2. To update licence record, only value that is meant to be updated is required.
   * 3. On first time activation of product systemId is updated to given licence.
   * 4. On de-activation of product from one system, systemId is cleared.
   * 5. On re-activation of product systemId is updated to given system's id.
   * 6. On renew of licence of product expiryDate date is updated.
   *
   * @param {string} uid : User's id
   * @param {string} licenceNo : Licence number for given product purchased by user
   * @param {object} data : Values to be updated
   *
   * data values can includes following:-
   *  {
   *   @param {string} id : Licence Number
   *   @param {string} productId : Product Id
   *   @param {string} expiryDate : Expiry date of licence
   *   @param {string} systemId : Unique id of system on which given product is activated
   *  }
   *
   */
  updateUserLicences = async (uid, licenceNo, data) => {
    this.database
      .ref(SCHEMA.USERS + "/" + uid + "/licence/" + licenceNo)
      .update(data);
  };

  /**
   * @method getUserLicences : To fetch user all licences
   *
   * @param {string} uid : User's unique auth id
   *
   * @returns Promise that resolves or rejects query
   */
  getUserLicences = (uid) => {
    return new Promise((resolve, reject) => {
      try {
        this.database
          .ref(SCHEMA.USERS + "/" + uid + "/licence")
          .once("value", (snapshot) => {
            resolve(Object.values(snapshot.val() || {}));
          });
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
    * @method applyForJob : To aply for job in CAMAX
    *
    * @param {object} data : applicants infomation data
    *
    */
  applyForJob = async (data, callback) => {
    await this.database.ref(SCHEMA.APPLICANTS + "/").push(data)
    callback(true)
  }

  /**
   * @method getUserLicenceByNumber : To fetch particuler licence detail
   *
   * @param {string} uid : User's unique auth id
   * @param {string} licenceNo : Licence number
   *
   * @returns Promise that resolves or rejects query
   */
  getUserLicenceByNumber = (uid, licenceNo) => {
    return new Promise((resolve, reject) => {
      try {
        this.database
          .ref(SCHEMA.USERS + "/" + uid + "/licence")
          .orderByChild("id")
          .equalTo(licenceNo)
          .once("value", (snapshot) => {
            resolve(Object.values(snapshot.val() || {}));
          });
      } catch (error) {
        reject(error);
      }
    });
  };

  /******************************************************/
  /****************** Storage *********************/
  /******************************************************/

  /**
   * @method handleUploadToFireBaseStorage : To handle upload image to firebase storage
   *
   * @param {object} file : file to be uploaded
   * @param {string} folderName : name of file folder
   * @param {string} fileName : name of file
   * @param {boolean} isEditMode : file is being updated
   */
  uploadFile = async ({ file, folderName, fileName, isEditMode }) => {
    // const file = uploadedfile 
    return new Promise(async (resolve, reject) => {
      if (!file) {
        if (isEditMode) {
          reject({
            message: "Invalid data!",
          });
          return;
        }
        reject({
          message: "Please provide valid file.",
        });
        return;
      }
      //initiates the firebase side uploading
      const uploadFile = this.storage
        .ref(folderName + "/" + (fileName || file.name))
        .put(file);
      await uploadFile.on(
        "state_changed",
        null,
        (err) => {
          if (err) {
            reject({
              message: "Error in file upload!!",
            });
          }
        },
        () => {
          // To get the download url then sets the image from firebase as the value for the imgage url :

          this.storage
            .ref(folderName)
            .child(fileName || file.name)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              resolve(fireBaseUrl);
            })
            .catch(() => {
              reject({
                message: "Error getting file url!!",
              });
            });
        }
      );
    });
  };

  /**
   * @method deleteFiles : To delete files from firebase storage
   *
   * @param {string} path : Filepath
   */
  deleteFiles = (path) => {
    return new Promise((resolve, reject) => {
      try {
        this.storage
          .ref(path)
          .delete()
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  };

/**
   * @method croneJobTest : To delete files from firebase storage
   *
   * @param {string} path : Filepath
   */

   croneJobTest = async ()=>{
   const response =  await this.getAllUserLicenceDetail()
   if(response){
     const data = await this.getExpiredLicences(response)
     console.log("datadatadata=",data)
     data.forEach((el)=>{

       this.sendProductEpiremail(el)
     })
   }
   }

   sendProductEpiremail = (detail)=>{
    
    const from = "mmahajaninnow8@gmail.com"
    const content = `
    <div> <p>Hi</p> <p> Your Licence ${detail.id} for product ${detail.productName}is going to expire on ${moment(detail.expiryDate)}.</p></div>
    `
    const data = {
      from: from,
      to: detail.email,
      subject: "CAMAX Licence Expire",
      content,
    };
    // sendMail(data)
    //   .then(() => {
    //     console.log("send mail")
    //   })
    //   .catch((errr) => {
    //     console.log("errrerrrerrr=",errr)

    //     // Alert(400, "Unable to send email at this time.");
    //   });
   }

 getExpiredLicences =(data)=>{
  let info = []
 const date = moment (new Date())
 data.forEach(el=>{
  const values = el.licence && Object.values(el.licence).find(data=>{
    const expiryDate = moment(data.expiryDate)
    var duration = Math.floor(moment.duration(expiryDate.diff(date)).asDays())
    if(duration === 30){
      return true
    }else if (duration === 20){
      return true
    }else if (duration === 10){
      return true
    }
   })
   if(values){
     let data = {
       ...values,
       email: el.email,
     }
    info.push(data)
   }
})
console.log("infoinfo=",info)
return info
 }

 getAllUserLicenceDetail = async () =>{
  const result = await this.database
  .ref(SCHEMA.USERS).orderByChild("licence").once("value")
     if(result){
      const rawData = Object.values(result.val()) || []
      const data =rawData.filter(el=>{
        return el.licence
      })
       return data
     }
 }



}

export { FirebaseContext, withFirebase };

export default Firebase;
