import React, { useState } from "react";
import { sendMail } from "../../shared/utils/email";
import {
  Row,
  Input,
  Label,
  Modal,
  ModalBody,
  FormGroup,
  Col,
  Button,
} from "reactstrap";
import { compose } from "redux";
import { withFirebase } from "../../firebase";
import { validateEmail } from "../../shared/utils/validations";
import Alert from "../../shared/utils/alert";
import { API_URL } from "../../shared/constants";
// import closeIcon from "../../../../assests/images/close.svg";
function ForgotPasswordLinkModal(props) {
  const { isOpen, onToggle } = props;
  const [email, setEmail] = useState();
  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);
  };
  const handleSend = () => {
    if (!validateEmail(email)) {
      Alert(400, "Please enter valid email address!!");
      return;
    }
    onToggle();
    // const content = `<div><p>Name: <b>${name}</b></p><p>Email: <b>${email}</b></p><p>Phone: <b>${phone}</b></p><p>Services: <b>${serviceType}</b></p><p>Message:</p><p>${message}</p></div>`;
    const link = API_URL.forgotpassword + "/" + email;
    const content = `<div> <p>Hello,</p>Please click on the link to change your password  <a href = ${link}> Change Password</a> <p>Thanks</p></div>`;
    const data = {
      from: "mmahajaninnow8@gmail.com",
      to: email,
      subject: "Inquiry from CaMax",
      content,
    };

    sendMail(data)
      .then(() => {
        Alert(200, "Email sent successfully.");
      })
      .catch(() => {
        Alert(400, "Unable to send email at this time.");
      });
  };
  return (
    <Modal isOpen={isOpen} toggle={onToggle} centered>
      <ModalBody>
        <div className="buy-license-wrapper p-3">
          <h2></h2>
          <Row>
            <Col>
              <FormGroup>
                <Label>Enter Email </Label>
                <Input
                  value={email}
                  type="text"
                  onChange={handleChange}
                  // id={field.id}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="text-center">
            <Button
              color="theme"
              onClick={handleSend}
              className="px-4 py-2 mt-2"
            >
              {" "}
              Send{" "}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
export default compose(withFirebase)(ForgotPasswordLinkModal);
// export default ForgotPasswordLinkModal;
