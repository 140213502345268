import React from "react";
// import { carrierOptions } from "../components/carrierOptionData";
import { Col, Container, Row } from "reactstrap";
import Experience from "../../../assests/images/carrier/experience.png";
import Location from "../../../assests/images/carrier/location.png";

function CarrierOptions(props) {
  const {carrierOptions} = props; 
  return (
    <section className="ca-position-wrapper">
      <Container>
        <div className="text-center">
          <h3>Open Positions at CA MAX</h3>
          <p className="subtext">
          Hey code Geeks,  Project managers, sales sorcerers,  and design enthusiasts this is for you.  CA Max is looking for experts to join our team. 
          </p>
        </div>

        <div className="d-flex justify-content-center flex-wrap open-position">
          {carrierOptions &&
            carrierOptions.map((data) => {
              return (
                <div className="position-content-wrapper">
                  <div className="ca-position-content">
                    <h3>{data.jobTitle} </h3>
                    <div>
                      <p>
                        <img src={Experience} className="mr-2" />
                        {data.experience}{" "}
                      </p>
                      <hr />
                      <p>
                        <img src={Location} className="mr-2" />
                        {data.location}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </Container>
    </section>
  );
}

export default CarrierOptions;
