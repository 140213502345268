import React from "react";
import Facebook from "../../../../assests/images/facebook.png";
import Instagram from "../../../../assests/images/instagram.png";
import SnapChat from "../../../../assests/images/snapchat.png";

const ContactUs = () => (
  <div>
    <h3>Contact with Us</h3>
    <p> Address : DSS - 147 P, Sector - 11, Panchkula- Haryana -134109, India </p>
    <p> Contact :  <br/>  +91-9814-000-063</p>
    
    {/* <a href="#">
      <img src={Facebook} alt="fb" className="mr-1" />
    </a>
    <a href="#">
      <img src={Instagram} alt="instagram" className="mr-1" />
    </a>
    <a href="#">
      <img src={SnapChat} alt="snapchat" className="mr-1" />
    </a> */}
  </div>
);

export default ContactUs;
