import React, { useState,useEffect } from "react";
import DashboardContent from "./component";
// import { withFirebase } from "../../../../firebase";
// import { compose } from "redux";
// import { connect } from "react-redux";
const DashboardContentContainer = ({licenceData,togglePayment,paymentDone}) => {
  const [isOpenLicenceModal, setOpenLicenceModal] = useState(false);
//   const [licenceData, setLicenceData] = useState();
//  const {firebase,user}  = props;
//  const [paymentDone , setPaymentDone] = useState(false)
 
  const handleToggleLicenceModal = () => {
    setOpenLicenceModal((prevState) => !prevState);
  };

 
  return (
    <>
    <DashboardContent
      isOpenLicenceModal={isOpenLicenceModal}
      onToggleLicenceModal={handleToggleLicenceModal}
      licenceData = {licenceData}
      togglePayment = {togglePayment}
      paymentDone = {paymentDone}
    />
   
    </>
  );
};
// const mapStateToProps = (state) => ({
//   user: state.user,
// });
// export default connect(mapStateToProps)(DashboardContentContainer);
// export default compose(withFirebase)(DashboardContentContainer);
// export default compose(
//   withFirebase,
//   connect(mapStateToProps)
// )(DashboardContentContainer);

export default DashboardContentContainer;
