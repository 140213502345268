import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Header from "./component";
import { withFirebase } from "../../../../firebase";
import { ROUTES } from "../../../../shared/constants";
import * as actions from "../../../../redux/actions";

const HeaderContainer = ({ onSearch, user, title, firebase, history, reset }) => {
  const [searchText, setSearchText] = useState("");
 
  const handleSearchLicence = (event) => {
    const value = event.target.value;
    setSearchText(value);
    //ToDo: Handle search functionality
    onSearch(value)
  };

  const handleLogout = () => {
    firebase.signOut();
    reset();
    history.push(ROUTES.home);
  };

  return (
    <Header
      title={title}
      userDetail={user}
      searchText={searchText}
      onChange={handleSearchLicence}
      onLogout={handleLogout}
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    reset: () => dispatch(actions.resetReducer()),
  };
};

export default compose(
  withFirebase,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(HeaderContainer);
