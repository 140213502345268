import Email from "../../../../assests/images/commonImages/email.png";
import Profile from "../../../../assests/images/commonImages/profile.png";
import Phone from "../../../../assests/images/commonImages/phone-number.png";
// import Email from "../../../../assests/images/contact/email.png";
// import Profile from "../../../../assests/images/contact/profile.png";

const formFields = {
  section1: {
    name: {
      id: "name",
      label: "Name",
      type: "text",
      fieldType: "input",
      icon: Profile,
      required : true,
      
    },
    email: {
      id: "email",
      label: "Email",
      type: "email",
      fieldType: "input",
      icon: Email,
      required : true,

    },
    phone: {
      id: "phone",
      label: "Contact",
      type: "text",
      fieldType: "input",
      icon: Phone,
      required : true,

    },
  },
  section2: {
    jobTitle: {
      id: "jobTitle",
      label: "Job Title",
      type: "text",
      fieldType: "input",
      required : true,

    },
    experience: {
      id: "experience",
      label: "Experience",
      type: "text",
      fieldType: "input",
      required : true,

    },
    ctc: {
      id: "ctc",
      label: "CTC (optional)",
      type: "text",
      fieldType: "input",
      required : false,
    },
  },
  section3: {
    about: {
      id: "about",
      label: "About",
      type: "textarea",
      fieldType: "input",
      required : true,

    },
  },
};

export default formFields;
