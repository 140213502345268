import React, { useState } from "react";
import { formData } from "./formData";
import Upload from "../../../../assests/images/carrier/upload.png";
import Dropzone from "react-dropzone";
import { compose } from "redux";
import { withFirebase } from "../../../../firebase";
import Select from 'react-select'
import {
  Container,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  InputGroup,
} from "reactstrap";
import Arrowleft from "../../../../assests/images/contact/arowleft.png";
function ApplyInCAMAx(props) {
  const {
    values,
    onChange,
    onSubmit,
    firebase,
    uploadCV,
    availablePost,
  } = props;
  const [cv, setCV] = useState();
  const [render, setRender] = useState();
  const [warningMsg, setWarning] = useState()
  const addFile = (file) => {
    uploadCV(file[0]);
    const newFile = file.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setCV(newFile);
  };
  const onDrop = (accepted, rejected) => {
    if (Object.keys(rejected).length !== 0) {
      const message = "Please submit valid file type";
      setWarning({ warningMsg: message })
      // this.setState({ warningMsg: message });
    } else {
      addFile(accepted);
      var blobPromise = new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        reader.readAsDataURL(accepted[0]);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
      blobPromise.then((value) => { });
    }
  };
  const files =
    cv && cv.length && Object.keys(cv).length !== 0
      ? cv.map((file) => <p key={file.name}>{file.name}</p>)
      : null;
// console.log("availablePostavailablePost=",availablePost)
  return (
    <div className="employee-form-wrapper">
      <Container>
        <Card>
          <CardBody>
            {Object.keys(formData).map((section) => {
              return (
                <div>
                  {Object.keys(formData[section]).map((key) => {
                    const field = formData[section][key];
                    return (
                      <FormGroup>
                        <label>{field.label}</label>
                        <InputGroup
                          className={`${field.icon ? "icon-group" : ""}`}
                        >
                          
                          {field.id !== "position" ? (
                            <Input
                              value={values[key]}
                              type={field.type}
                              onChange={onChange}
                              id={field.id}
                            />
                          ) :

                            (
                              <>
                                <Select className="position-select"
                                  value={values[key]}
                                  onChange={onChange}
                                  options={availablePost}
                                  classNamePrefix="position"
                                />
                                {/* <option value={""}>- Select Job-</option>
                                {availablePost &&
                                  availablePost.map((val) => (
                                    <option
                                      value={val.id}
                                      key={"position" + val.id}
                                    >
                                      {val.position}
                                    </option>
                                  ))} */}
                              
                            </>
                          )
                          }
                        <img src={field.icon} />
                        </InputGroup>
                      </FormGroup>
              );
            })}

            <Dropzone
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
                border: " 2px dashed",
              }}
              multiple={false}
              onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
              acceptedFiles={cv}
              accept=".PDF"
            >
              {({
                getRootProps,
                getInputProps,
                isDragAccept,
                isDragReject,
                acceptedFiles,
                rejectedFiles,
              }) => {
                // if (isDragReject) {
                //   return "Please submit a valid file";
                // } else {
                return (
                  <label {...getRootProps()} className="upload-files">
                    <input {...getInputProps()} />
                    <img src={Upload} />
                    { (isDragReject) ? <p>Please submit a valid file </p> : <p>{files ? files : "Upload your CV"}</p>}
                  </label>
                );
                // }
              }}
            </Dropzone>
                </div>
              );
            })}
            <div className="text-center mb-5">
            <Button
              onClick={() => {
                setCV([]);
                onSubmit();
              }}
              color="theme"
              className="employee-submit-button"
            >
              Submit <img src={Arrowleft} className="ml-1" />
            </Button>
          </div>
          </CardBody>
        </Card>
      </Container>
    </div >
  );
}
export default compose(withFirebase)(ApplyInCAMAx);

// export default ApplyInCAMAx;
