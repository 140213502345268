import React from "react";
import { Container } from "reactstrap";
import Product from "./components/product";
import ProductList from "./productList";

const ListContainer = () => {
  return (
    <section className="product-list-wrapper">
      <Container>
        {Object.values(ProductList || {}).map((value) => (
          <Product key={value.id} {...value} />
        ))}
      </Container>
    </section>
  );
};

export default ListContainer;
