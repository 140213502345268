import React from "react";
import { Button, Input, Container, Row, Col } from "reactstrap";
import commingSoon from "../../../../assests/images/home/comming-soon.png";
import AppleIcon from "../../../../assests/images/downloads/appleicon.png";
import Playstore from "../../../../assests/images/downloads/playstore.png";
import { appLinks } from ".././../../downloads/components/constants";

const ComingSoon = ({ value, onChange, handleSubsciption }) => {
  return (
    <section className="coming-soon-wrapper ">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <div className="coming-soon-img">
              <img
                src={commingSoon}
                alt="coming_soon_img"
                className="img-fluid"
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="text-lg-left text-center">
              <div className="pocket-text">
                {" "}
                <span>All your accounts in your pocket!</span>
              </div>
              {/* <p > All your accounts in your pocket! </p> */}
              <h3>CA MAX MOBILE APP IS AVAILABLE ON STORES</h3>
              {/* <h1>Coming Soon</h1> */}
              {/* <p>
                Enter your mobile number to get notification about latest updates
              </p> */}

              <div className="d-flex comming-soon-inputs">
                {/* <Input
                  placeholder="Enter mobile number"
                  value={value}
                  onChange={onChange}
                />
                <Button onClick={handleSubsciption} color="theme">
                  Subscribe
                </Button> */}
                 <h3>DOWNLOAD CA MAX MOBILE APP</h3>
              </div>
              <div className="d-flex flex-md-row flex-column">
                <a href={appLinks.android} target = "blank">
                  <div className="stores-button">
                    <img src={Playstore} />
                    <div>
                      <p>GET IT ON</p>
                      <h4>Google Play</h4>
                    </div>
                  </div>
                </a>
                <a href={appLinks.ios} target = "blank">
                  <div className="stores-button ml-md-2">
                    <img src={AppleIcon} />
                    <div>
                      <p>Download on the</p>
                      <h4>App Store</h4>
                    </div>
                  </div>
                </a>
              </div>
              <a className="read-link" href={"https://firebasestorage.googleapis.com/v0/b/camax-81a85.appspot.com/o/Downloads%2FIntallation%20of%20Mob%20App.pdf?alt=media&token=51601ebe-b19f-416c-af9d-d1f9519ee194"} target="blank">Read the help file to setup google drive on your computer</a>
<br/>
              <a className="read-link" href={"https://firebasestorage.googleapis.com/v0/b/camax-81a85.appspot.com/o/Downloads%2Finstallbackupandsync.exe?alt=media&token=f325a150-3275-4123-b647-0232d088d39c"} target="blank">Download Setup File to Install google drive</a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ComingSoon;
