export default {
  dashboard: {
    id: "dashbord",
    label: "Dashboard",
    title: "Dashboard",
  },
  editProfile: {
    id: "editProfile",
    label: "Edit Profile",
    title: "Profile",
  },
  // settings: {
  //   id: "settings",
  //   label: "Settings",
  //   title: "Settings",
  // },
};

/**
 * @constant TAB_KEY_LIST
 */
export const TAB_KEY_LIST = {
  DASBOARD: "dashboard",
  EDIT_PROFILE: "editProfile",
  SETTINGS: "settings",
};
