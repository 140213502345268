import React from "react";
import { withRouter } from "react-router-dom";

import ContactUs from "./component";

const ContactUsContainer = () => {
  return <ContactUs />;
};

export default withRouter(ContactUsContainer);
