import React from 'react';
import { withRouter } from "react-router-dom";
import {ROUTES} from '../../../../shared/constants'
function PrivacyPolicy(props) {
  const {history} = props
  const redirectTo = (navigateTo)=>{
    history.push(navigateTo);
    
  }
  return (
    <div className="footer-detail">
    <h3 style = {{"color" : "white"}}> Privacy Policies</h3>

    <p onClick = {()=>{redirectTo(ROUTES.privacyPolicies)}}>Privacy Policies</p>
    <p onClick = {()=>{redirectTo(ROUTES.termsConditions)}}>Terms and Conditions</p>
    </div>
  );
}
export default withRouter(PrivacyPolicy)

// export default PrivacyPolicy;