import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { downloadProducts } from "./constants";
import Setup from "../../../assests/images/downloads/setup.png";
function Details(props) {
  return (
    <section className="download-details-wrapper">
      <Container>
        <h4 className="custom-title">
          <span className="text-contain">
            <span className="text">Download details </span>
            <span className="backText">Download</span>
          </span>
        </h4>
        <div className="download-card-wrapper">
          {downloadProducts &&
            downloadProducts.map((product) => {
              return (
                <div className="download-card">
                  <img src={Setup} />
                  <h2>{product.productDescription}</h2>
                  <div className="download-user-btn" >
                    <Button color="bordered" className="mb-2"  onClick={()=>{window.open(product.singleuserUrl, "_blank") }}>
                      {product.singleUserLinkText}
                    </Button>
                    <Button color="bordered" onClick={()=>{window.open(product.multiUserUrl, "_blank") }}>
                      {product.multiuserLinkText}
                    </Button>
                  </div>
                </div>
              );
            })}
        </div>
      </Container>
    </section>
  );
}

export default Details;
