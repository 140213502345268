import React from "react";
import { withRouter } from "react-router-dom";

import Home from "./component";

const HomeContainer = () => {
  return <Home />;
};

export default withRouter(HomeContainer);
