import React from "react";
import { Container, Row, Col } from "reactstrap";
import ProductImage from "../../../../assests/images/products/productImage.png";
import about1 from "../../../../assests/images/home/about-1.png";

const DescriptionContainer = () => {
  return (
    <div className="banner-wrapper products-wrapper">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <div className="product-content">
              <h3>
                {/* <span>Lorem Ipsum is simply Lorem</span> */}
              </h3>
              <h1 style={{ maxWidth: "390px" }}>
              Delivering Top class <span> software </span> just in time
              </h1>
              <p>
              Be any business we offer you the most reliable products to manage your business unmitigatedly. From Accounting to End to End ERP Solutions, do everything with absolute precision.
              </p>
            </div>
          </Col>
          <Col md={6} className="d-md-block d-none ">
            <div>
              <img src={ProductImage} className="img-fluid" alt="banner" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DescriptionContainer;
